import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Link, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import RenameDialog from '../../../shared/components/rename-dialog';
import useDialog from '../../../shared/hooks/use-dialog';
import { Platform } from '../../../shared/types/platforms';
import useAmazonApi from '../../hooks/use-amazon-api';
import DeleteBidGroupDialog from './delete-bid-group-dialog';

export const BidGroupNameActionCell = (params: GridRenderCellParams) => {
  const { updateBidOptimizationGroup, deleteBidOptimizationGroup } = useAmazonApi();
  const apiRef = useGridApiContext();
  const [searchParams] = useSearchParams();

  const profileId = searchParams.get('profileId');

  const [isHovering, setIsHovering] = useState(false);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { toggle: toggleRenameDialog, isShowing: renameDialogIsShowing } = useDialog();
  const { toggle: toggleDeleteDialog, isShowing: deleteDialogIsShowing } = useDialog();

  const handleActionMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setActionMenuAnchorEl(actionMenuAnchorEl ? null : event.currentTarget);
  };

  const open = Boolean(actionMenuAnchorEl);

  const handleActionMenuClose = () => {
    setActionMenuAnchorEl(null);
    setIsHovering(false);
  };

  const handleRemoveBidGroupRow = (bidGroupId: string) => {
    apiRef.current.updateRows([{ id: bidGroupId, _action: 'delete' }]);
  };

  useEffect(() => {
    setIsHovering(false);
  }, [deleteDialogIsShowing]);

  return (
    <Box
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        width: '100%',
        height: '100%',
        minWidth: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative'
      }}
    >
      <Link
        to={`/${Platform.AMAZON.value}/bid-optimization-groups/overview?profileId=${profileId}&bidGroupId=${params.row.id}`}
        sx={{
          textDecoration: 'none',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whitespace: 'nowrap',
          color: 'text.primary'
        }}
        component={RouterLink}
      >
        {params.value}
      </Link>
      {isHovering ? (
        <>
          <Box>
            {/* Todo: Add menu item for dialog that adds Campaigns to Bid Group */}
            <Tooltip title="Actions" placement="top">
              <IconButton onClick={handleActionMenuIconClick} size="small" color="primary">
                <MoreVertIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            id="action-menu"
            anchorEl={actionMenuAnchorEl}
            open={open}
            onClose={handleActionMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem
              onClick={() => {
                toggleRenameDialog();
                handleActionMenuClose();
              }}
            >
              Rename
            </MenuItem>
            <MenuItem
              onClick={() => {
                toggleDeleteDialog();
                handleActionMenuClose();
              }}
            >
              <Typography color={'error'}>Delete</Typography>
            </MenuItem>
          </Menu>
        </>
      ) : null}
      {renameDialogIsShowing && (
        <RenameDialog
          isOpen={renameDialogIsShowing}
          toggleRenameDialog={toggleRenameDialog}
          entityType={'BidOptimizationGroup'}
          entityIdKey="id"
          currentEntityId={params.row.id}
          entityNameKey="name"
          currentEntityName={params.row.name}
          updateEntityFunction={updateBidOptimizationGroup}
          apiRef={apiRef}
        />
      )}
      {deleteDialogIsShowing && (
        <DeleteBidGroupDialog
          isOpen={deleteDialogIsShowing}
          toggle={toggleDeleteDialog}
          bidGroup={params.row}
          onDelete={deleteBidOptimizationGroup}
          removeBidGroupRow={handleRemoveBidGroupRow}
        />
      )}
    </Box>
  );
};
