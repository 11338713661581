import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import { Box, Button, Card, CardContent, CircularProgress, Divider, TextField, Tooltip, Collapse } from '@mui/material';
import AddItemsAlert from '../../../../shared/pages/campaigns/create-campaign-form/add-items-alert';
import { useSnackbar } from 'notistack';
import { useAdsApi } from '../../../../shared/hooks/use-walmart-sams-club-api';
import { CampaignItemsTable } from './campaign-items-table';
import { CampaignBuilderStepLabelButton } from './step-label-button';

interface CampaignTypeStepProps {
  onBack?: () => void;
  onNext?: () => void;
  formik: any;
  stepNumber: number;
  profileId: number | null;
  campaignId?: number | null;
}

export const ItemSelectionStep: FC<CampaignTypeStepProps> = (props) => {
  const { onBack, onNext, formik, stepNumber, profileId, campaignId, ...other } = props;

  const [eligibleItems, setEligibleItems] = useState<any[]>([]);
  const [invalidItems, setInvalidItems] = useState<string[]>([]);
  const [alreadyTargetedItems, setAlreadyTargetedItems] = useState<any[]>([]);
  const [userInputItemIds, setUserInputItemIds] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [itemSearchIsLoading, setItemSearchIsLoading] = useState(false);

  const { samsClubItemSearch } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [showStep, setShowStep] = useState<boolean>(true);

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  const addItems = async () => {
    setItemSearchIsLoading(true);
    setIsAlertOpen(false);

    // Clean and split input
    const itemIds = userInputItemIds
      .replace(/,\s+/g, ',')
      .split(/[\n,\s+]/)
      .filter(Boolean);
    const newItemIds = itemIds.filter((itemId) => !formik.values.items.some((item: any) => item.itemId === itemId));

    if (newItemIds.length < 1) {
      setItemSearchIsLoading(false);
      return;
    }

    const response = await samsClubItemSearch(profileId, newItemIds, campaignId);

    if (response.success) {
      const eligibleItems = response.body?.eligibleItems || [];
      const alreadyTargetedItems = response.body?.alreadyTargetedItems || [];

      setAlreadyTargetedItems(alreadyTargetedItems);

      setEligibleItems(eligibleItems);

      const trulyInvalidItems = newItemIds.filter(
        (newItemId: string) =>
          !eligibleItems.some((foundItem: any) => foundItem.itemId === newItemId) &&
          !alreadyTargetedItems.some((existingItem: { itemId: number }) => existingItem.itemId.toString() === newItemId)
      );
      setInvalidItems(trulyInvalidItems);

      formik.setFieldValue('items', [...formik.values.items, ...eligibleItems]);
      setIsAlertOpen(true);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setItemSearchIsLoading(false);
  };

  const removeItem = (item: any) => {
    const filteredItems = formik.values.items.filter((campaignItem: any) => campaignItem.itemId !== item.itemId);
    formik.setFieldValue('items', filteredItems);
  };

  const handleClearAllItems = () => {
    formik.setFieldValue('items', []);
    setEligibleItems([]);
    setInvalidItems([]);
    setAlreadyTargetedItems([]);
  };

  return (
    <Box {...other} sx={{ mb: showStep ? 10 : 1 }}>
      <CampaignBuilderStepLabelButton
        showStep={showStep}
        handleStepButtonClick={handleStepButtonClick}
        stepNumber={stepNumber}
        stepLabel="Select Items"
      />
      <Collapse in={showStep}>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Box display={'flex'}>
              <Box sx={{ width: '30%', pr: 2 }}>
                <TextField
                  id="campaignItemIds"
                  label="Items"
                  onChange={(event) => {
                    setUserInputItemIds(event.target.value);
                  }}
                  placeholder="Enter item IDs separated by new lines"
                  multiline
                  value={userInputItemIds}
                  rows={15}
                  fullWidth
                  inputProps={{
                    style: {
                      fontSize: '12px'
                    }
                  }}
                />
                {!profileId ? (
                  <Tooltip title="Please select a Profile before adding Items">
                    <span>
                      <Button disabled>Add Items</Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Button variant="outlined" onClick={addItems} disabled={!userInputItemIds}>
                    Add Items
                  </Button>
                )}
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box sx={{ width: '70%', pl: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="outlined" sx={{ mb: 2 }} onClick={handleClearAllItems}>
                    Remove All Items
                  </Button>
                </Box>
                <AddItemsAlert
                  eligibleItems={eligibleItems}
                  invalidItems={invalidItems}
                  alreadyTargetedItems={alreadyTargetedItems}
                  isOpen={isAlertOpen}
                  onClose={() => {
                    setIsAlertOpen(false);
                    setEligibleItems([]);
                    setInvalidItems([]);
                    setAlreadyTargetedItems([]);
                  }}
                />
                {!itemSearchIsLoading ? (
                  <CampaignItemsTable formik={formik} removeItem={removeItem} />
                ) : (
                  <Box display={'flex'} alignItems="center" justifyContent={'center'} sx={{ height: '100%' }}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Box sx={{ mt: 3 }}></Box>
      </Collapse>
    </Box>
  );
};

ItemSelectionStep.propTypes = {
  onBack: PropTypes.func
};
