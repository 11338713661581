import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Page from '../../../shared/components/page';
import ProfileSelect from '../../../shared/components/page-components/profile-select';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useDatelessVectorPerformanceGrid from '../../../shared/hooks/use-dateless-vector-performance-grid';
import useDialog from '../../../shared/hooks/use-dialog';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import useAmazonApi from '../../hooks/use-amazon-api';
import {
  BidOptimizationGroupCreateDto,
  BidOptimizationGroupDto,
  BidOptimizationGroupStatus
} from '../../types/bid-optimization-group';
import { BID_GROUP_COLUMNS } from './bid-group-columns';
import CreateBidGroupDialog from './create-bid-group-dialog';

export interface BidOptimizationGroupRow {
  id: string;
  profileId: string;
  name: string;
  status: BidOptimizationGroupStatus;
  acosTarget: number;
  campaignIds: string[];
}

const BidOptimizationGroups: FC = () => {
  const { getBidOptimizationGroups, getProfilesWithoutMetrics, updateBidOptimizationGroup, createBidOptimizationGroup } =
    useAmazonApi();

  const [bidOptimizationGroups, setBidOptimizationGroups] = useState<BidOptimizationGroupRow[]>([]);
  const { toggle: toggleCreateBidGroupDialog, isShowing: createBidGroupDialogIsShowing, dynamicKey } = useDialog();
  const [rowCount, setRowCount] = useState<number>(DEFAULT_ROW_COUNT);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let [searchParams] = useSearchParams();
  let profileId = useMemo(() => (searchParams.get('profileId') ? searchParams.get('profileId') : ''), [searchParams]);

  const {
    initializeDatelessPerformanceGrid,
    saveGridConfig,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange,
    handleRefreshEntities
  } = useDatelessVectorPerformanceGrid(GridConfigSettings.AMAZON_BID_GROUPS);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const fetchBidOptimizationGroups = useCallback(
    async (pageable: Pageable) => {
      if (!profileId) {
        return;
      }

      setIsLoading(true);

      const response = await getBidOptimizationGroups(profileId, pageable);

      if (response.success) {
        setBidOptimizationGroups(response.body?.records);
        setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setIsLoading(false);
    },
    [profileId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!profileId) {
      return;
    }

    initializeDatelessPerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      fetchEntity: fetchBidOptimizationGroups
    });
  }, [profileId]); // eslint-disable-line react-hooks/exhaustive-deps

  const processRowUpdate = useCallback(async (newRow: BidOptimizationGroupRow, oldRow: BidOptimizationGroupRow) => {
    if (!profileId) {
      return oldRow;
    }

    const bidOptimizationGroupDto: BidOptimizationGroupDto = {
      id: newRow.id,
      profileId: profileId,
      name: newRow.name,
      status: newRow.status,
      acosTarget: newRow.acosTarget,
      maximumTargetBidAdjustmentPercentage: null,
      maximumPlacementAdjustmentPercentage: null,
      placementAcosThresholdPercentage: null,
      shouldDecreaseBidForNoConversions: null,
      noConversionBidDecreasePercentage: null,
      lookbackWindowDelayDays: 2,
      campaignIds: [...newRow.campaignIds]
    };

    const response = await updateBidOptimizationGroup(bidOptimizationGroupDto);

    if (response.success) {
      enqueueSnackbar('Bid Optimization Group updated successfully', { variant: 'success' });
      return response.body;
    }

    enqueueSnackbar(response.errorMessage, { variant: 'error' });
    return oldRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateBidGroup = async (bidOptimizationGroupCreateDto: BidOptimizationGroupCreateDto) => {
    if (!profileId) {
      return;
    }

    const response = await createBidOptimizationGroup(bidOptimizationGroupCreateDto);

    if (response.success) {
      enqueueSnackbar('Bid Optimization Group created successfully', { variant: 'success' });
      toggleCreateBidGroupDialog();
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  return (
    <Page title="Bid Optimization Groups">
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Box display="flex" alignItems={'center'}>
            <Typography variant="h5" mr={2}>
              Bid Optimization Groups
            </Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
            <Box>
              <Button variant="contained" color="primary" onClick={toggleCreateBidGroupDialog}>
                Create Bid Group
              </Button>
            </Box>
          </Box>
          <ProfileSelect profileId={profileId} getProfiles={getProfilesWithoutMetrics} />
          <Box height={'100%'}>
            {!settingsLoaded ? (
              <DataGridPremium
                rows={[]}
                columns={BID_GROUP_COLUMNS}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
                key={uuidv4()}
                disableRowGrouping
              />
            ) : (
              <VectorPerformanceGrid
                apiRef={apiRef}
                loading={isLoading}
                rows={bidOptimizationGroups}
                columns={BID_GROUP_COLUMNS}
                initialState={initialSettings?.config}
                saveGridConfig={saveGridConfig}
                processRowUpdate={processRowUpdate}
                bottomMargin={30}
                allowQuickFilterSearch={false}
                getRowId={(row) => row.id}
                getRowClassName={(params) => `row-status--${params.row.status}`}
                disableGridDateRangePicker
                handleFilterModelChange={(newModel: GridFilterModel) => {
                  handleFilterModelChange(newModel, settingsLoaded, apiRef, fetchBidOptimizationGroups);
                }}
                handleSortModelChange={(newModel: GridSortModel) => {
                  handleSortModelChange(newModel, settingsLoaded, apiRef, fetchBidOptimizationGroups);
                }}
                handlePageChange={(page: number) => {
                  handlePageChange(page, settingsLoaded, apiRef, fetchBidOptimizationGroups);
                }}
                handlePageSizeChange={(pageSize: number) => {
                  handlePageSizeChange(pageSize, settingsLoaded, apiRef, fetchBidOptimizationGroups);
                }}
                rowCount={rowCount}
              />
            )}
          </Box>
        </Stack>
      </Container>
      {createBidGroupDialogIsShowing && (
        <CreateBidGroupDialog
          isShowing={createBidGroupDialogIsShowing}
          toggle={toggleCreateBidGroupDialog}
          dynamicKey={dynamicKey}
          handleCreateBidGroup={handleCreateBidGroup}
          refreshEntities={() => handleRefreshEntities(settingsLoaded, apiRef, fetchBidOptimizationGroups)}
        />
      )}
    </Page>
  );
};

export default BidOptimizationGroups;
