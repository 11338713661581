import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { ResponseObject } from '../../../shared/utilities/fetch-utilities';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { CAMPAIGN_COLUMNS } from '../../components/grid-components/column-configurations/campaign-columns';
import { BreadCrumbParams } from '../../hooks/use-amazon-api-v2';
import { Campaign } from '../../types/campaign';
import { CampaignsGridModalActions } from './campaigns-grid-modal-actions';

interface CampaignsGridForDialogProps {
  getCampaigns: (
    breadcrumbParams?: BreadCrumbParams[],
    pageable?: Pageable,
    beginDate?: string | null,
    endDate?: string | null,
    shouldIncludeChartMetrics?: boolean
  ) => Promise<ResponseObject>;
  handleAddCampaigns?: () => void;
  setSelectedCampaignData?: (data: Partial<Campaign>[]) => void;
  isBidGroup?: boolean;
  isBudgetGroup?: boolean;
  gridHeight?: string | number;
}

const CampaignsGridForDialog: FC<CampaignsGridForDialogProps> = ({
  getCampaigns,
  handleAddCampaigns,
  setSelectedCampaignData,
  isBidGroup = false,
  isBudgetGroup = false
}: CampaignsGridForDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  const [searchParams] = useSearchParams();
  const profileId = searchParams.get('profileId');

  const breadcrumbParams: BreadCrumbParams[] = useMemo(() => [{ profileId }], [profileId]);

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange
  } = useVectorPerformanceGrid(GridConfigSettings.AMAZON_CAMPAIGN_BUDGET_DIALOG);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchCampaignsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCampaignsData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    if (!breadcrumbParams) {
      return;
    }

    setIsLoading(true);

    const response = await getCampaigns([...breadcrumbParams], pageable, beginDate, endDate, true);

    if (response.success && response.body) {
      setCampaigns(response.body?.dataGrid?.records);
      setRowCount(response.body?.dataGrid?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    return { ...oldRow };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
        <DataGridPremium
          rows={[]}
          disableRowGrouping
          columns={CAMPAIGN_COLUMNS}
          loading={true}
          processRowUpdate={processRowUpdate}
          initialState={{}}
        />
      ) : (
        <>
          <VectorPerformanceGrid
            apiRef={apiRef}
            loading={isLoading}
            processRowUpdate={processRowUpdate}
            rows={campaigns}
            columns={CAMPAIGN_COLUMNS}
            initialState={initialSettings?.config}
            saveGridConfig={saveGridConfig}
            dateConfig={{
              dateSettings: dateSettings,
              dateSettingsLoading: dateSettingsLoading,
              saveDateConfig: (dateRange: DateRange<Dayjs>) =>
                handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchCampaignsData, setDateSettings)
            }}
            getRowId={(row: any) => row.campaignId}
            getRowClassName={(params) => `row-status--${params.row.status}`}
            bulkActions={
              <CampaignsGridModalActions
                handleAddCampaigns={handleAddCampaigns}
                setSelectedCampaignData={setSelectedCampaignData}
                isBidGroup={isBidGroup}
                isBudgetGroup={isBudgetGroup}
              />
            }
            handleFilterModelChange={(newModel: GridFilterModel) => {
              handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
            }}
            handleSortModelChange={(newModel: GridSortModel) => {
              handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
            }}
            handlePageChange={(page: number) => {
              handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
            }}
            handlePageSizeChange={(pageSize: number) => {
              handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
            }}
            rowCount={rowCount}
          />
        </>
      )}
    </>
  );
};

export default CampaignsGridForDialog;
