import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { CAMPAIGN_COLUMNS } from '../../components/grid-components/column-configurations/campaign-columns';
import { BreadCrumbParams, useAmazonApi } from '../../hooks/use-amazon-api-v2';
import { Campaign, getUpdateCampaignRequest } from '../../types/campaign';
import CampaignBulkActions from '../campaigns/bulk-actions/campaign-bulk-actions';
import { AddCampaignsToBidGroupActions } from './add-campaigns-to-bid-group-actions';

interface BidGroupCampaignsGridProps {
  breadCrumbParams: BreadCrumbParams[];
  isLoadingCampaignRows: boolean;
  setIsLoadingCampaignRows: React.Dispatch<React.SetStateAction<boolean>>;
  setCampaignTimeSeriesData: React.Dispatch<React.SetStateAction<any[]>>;
}

export const BidGroupCampaignsGrid = (props: BidGroupCampaignsGridProps) => {
  const { breadCrumbParams, isLoadingCampaignRows, setIsLoadingCampaignRows, setCampaignTimeSeriesData } = props;

  const { getCampaigns, updateCampaign } = useAmazonApi();

  const [campaignRows, setCampaignRows] = useState<Campaign[]>([]);
  const [campaignRowCount, setCampaignRowCount] = useState(0);

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange,
    handleRefreshEntities
  } = useVectorPerformanceGrid(GridConfigSettings.AMAZON_CAMPAIGN_BID_OPTIMIZATION_GROUPS);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchCampaignsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCampaignsData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    setIsLoadingCampaignRows(true);

    const response = await getCampaigns([...breadCrumbParams], pageable, beginDate, endDate, true);

    if (response.success && response.body) {
      setCampaignRows(response.body?.dataGrid?.records);
      setCampaignRowCount(response.body?.dataGrid?.totalFilteredRecords || DEFAULT_ROW_COUNT);

      if (response.body?.timeSeriesData) {
        setCampaignTimeSeriesData(response.body.timeSeriesData); // set the time series data for the metrics chart in parent component
      }
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoadingCampaignRows(false);
  };

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    const updateCampaignRequest = getUpdateCampaignRequest(newRow, oldRow);

    if (!updateCampaignRequest) {
      return oldRow;
    }

    setIsLoadingCampaignRows(true);

    const response = await updateCampaign(updateCampaignRequest);

    setIsLoadingCampaignRows(false);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    return newRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!settingsLoaded || dateSettingsLoading || !dateSettings) {
    return (
      <DataGridPremium
        rows={[]}
        disableRowGrouping
        columns={CAMPAIGN_COLUMNS}
        loading={true}
        processRowUpdate={() => {}}
        initialState={{}}
      />
    );
  }

  return (
    <VectorPerformanceGrid
      apiRef={apiRef}
      key={'campaign-grid-key'}
      loading={isLoadingCampaignRows}
      rows={campaignRows}
      columns={CAMPAIGN_COLUMNS}
      initialState={initialSettings?.config}
      saveGridConfig={saveGridConfig}
      dateConfig={{
        dateSettings: dateSettings,
        dateSettingsLoading: dateSettingsLoading,
        saveDateConfig: (dateRange: DateRange<Dayjs>) =>
          handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchCampaignsData, setDateSettings)
      }}
      getRowId={(row: any) => row['campaignId']}
      getRowClassName={(params: any) => `row-status--${params.row.status}`}
      processRowUpdate={processRowUpdate}
      bulkActions={
        <>
          <AddCampaignsToBidGroupActions
            refreshEntities={() => handleRefreshEntities(settingsLoaded, dateSettings, apiRef, fetchCampaignsData)}
          />
          <CampaignBulkActions
            refreshCampaigns={() => handleRefreshEntities(settingsLoaded, dateSettings, apiRef, fetchCampaignsData)}
            setIsLoading={setIsLoadingCampaignRows}
          />
        </>
      }
      bottomMargin={30}
      disableGridDateRangePicker={false}
      allowQuickFilterSearch={true}
      rowCount={campaignRowCount}
      handleFilterModelChange={(newModel: GridFilterModel) => {
        handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
      }}
      handleSortModelChange={(newModel: GridSortModel) => {
        handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
      }}
      handlePageChange={(page: number) => {
        handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
      }}
      handlePageSizeChange={(pageSize: number) => {
        handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
      }}
    />
  );
};
