import PropTypes from 'prop-types';
import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import SplashScreen from './splash-screen';

export const AuthGuard: FC = () => {
  const { isAuthenticated, login, isInitialized, user } = useAuth();

  useEffect(() => {
    if (!isAuthenticated && isInitialized) {
      login();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isInitialized]);

  if (!isInitialized || !isAuthenticated) {
    return <SplashScreen />;
  }

  return <Outlet />;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
