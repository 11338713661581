import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

interface VectorLoadingProps {
  /**
   * Size of the loading component in pixels
   * @default 150
   */
  size?: number;
  /**
   * Override the theme color. If not provided, uses primary.main from theme
   */
  color?: string;
  /**
   * Animation interval in milliseconds
   * @default 1500
   */
  animationInterval?: number;
  /**
   * Animation duration for each dot in milliseconds
   * @default 200
   */
  animationDuration?: number;
  /**
   * Scale factor for the dot animation
   * @default 1.2
   */
  scaleFactor?: number;
  /**
   * Aria label for accessibility
   * @default "Loading..."
   */
  ariaLabel?: string;
}

const VectorLoading: React.FC<VectorLoadingProps> = ({
  size = 150,
  color,
  animationInterval = 1500,
  animationDuration = 200,
  scaleFactor = 1.2,
  ariaLabel = 'Loading...'
}) => {
  const theme = useTheme();

  // Create refs directly instead of using map
  const ref1 = useRef<SVGCircleElement>(null);
  const ref2 = useRef<SVGCircleElement>(null);
  const ref3 = useRef<SVGCircleElement>(null);
  const ref4 = useRef<SVGCircleElement>(null);
  const ref5 = useRef<SVGCircleElement>(null);
  const ref6 = useRef<SVGCircleElement>(null);

  // Group refs for easier access
  const circleRefs = [ref1, ref2, ref3, ref4, ref5, ref6];

  // Calculate dot color based on theme or prop
  const dotColor = color ?? theme.palette.primary.main;

  useEffect(() => {
    const diagonalDelays = [0, 80, 200];
    const dotOffset = 40;

    const animateDot = (ref: React.RefObject<SVGCircleElement>) => {
      if (ref.current) {
        ref.current.style.transform = `scale(${scaleFactor})`;
        setTimeout(() => {
          if (ref.current) {
            ref.current.style.transform = '';
          }
        }, animationDuration);
      }
    };

    const animateDots = () => {
      // Define animation sequence
      const sequence = [
        { ref: circleRefs[0], delay: diagonalDelays[0] },
        { ref: circleRefs[3], delay: diagonalDelays[0] + dotOffset },
        { ref: circleRefs[5], delay: diagonalDelays[0] + dotOffset * 2 },
        { ref: circleRefs[1], delay: diagonalDelays[1] },
        { ref: circleRefs[4], delay: diagonalDelays[1] + dotOffset },
        { ref: circleRefs[2], delay: diagonalDelays[2] }
      ];

      sequence.forEach(({ ref, delay }) => {
        setTimeout(() => animateDot(ref), delay);
      });
    };

    const intervalId = setInterval(animateDots, animationInterval);
    animateDots(); // Initial animation

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationInterval, animationDuration, scaleFactor]);

  return (
    <Box
      component="div"
      role="status"
      aria-label={ariaLabel}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: size,
        height: size
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width={size} height={size}>
        {/* First diagonal */}
        <circle
          ref={ref1}
          fill={dotColor}
          style={{ transition: `transform ${animationDuration}ms` }}
          cx="10.19"
          cy="10.19"
          r="10.19"
        />
        <circle
          ref={ref4}
          fill={dotColor}
          style={{ transition: `transform ${animationDuration}ms` }}
          cx="35.78"
          cy="35.78"
          r="10.19"
        />
        <circle
          ref={ref6}
          fill={dotColor}
          style={{ transition: `transform ${animationDuration}ms` }}
          cx="61.38"
          cy="61.38"
          r="10.19"
        />

        {/* Second diagonal */}
        <circle
          ref={ref2}
          fill={dotColor}
          style={{ transition: `transform ${animationDuration}ms` }}
          cx="35.78"
          cy="10.19"
          r="10.19"
        />
        <circle
          ref={ref5}
          fill={dotColor}
          style={{ transition: `transform ${animationDuration}ms` }}
          cx="61.38"
          cy="35.78"
          r="10.19"
        />

        {/* Third diagonal */}
        <circle
          ref={ref3}
          fill={dotColor}
          style={{ transition: `transform ${animationDuration}ms` }}
          cx="61.38"
          cy="10.19"
          r="10.19"
        />
      </svg>
    </Box>
  );
};

export default VectorLoading;
