import { FC } from 'react';
import { Alert, Link, Box, Typography } from '@mui/material';
import { downloadFileFromBlob } from '../../../../shared/utilities/file-export-utilities';

interface ItemRequestDto {
  itemId: string;
}

interface AddItemsAlertProps {
  eligibleItems: any[];
  invalidItems: string[];
  alreadyTargetedItems: ItemRequestDto[];
  isOpen: boolean;
  onClose: () => void;
}

export const AddItemsAlert: FC<AddItemsAlertProps> = (props) => {
  const { eligibleItems = [], invalidItems = [], alreadyTargetedItems = [], isOpen, onClose } = props;

  const getErrorReport = () => {
    let report = '';

    if (invalidItems.length > 0) {
      report += 'Not Found Items:\n' + invalidItems.join('\n');
    }

    if (alreadyTargetedItems.length > 0) {
      if (report) report += '\n\n';
      report += 'Already Existing Items:\n' + alreadyTargetedItems.map((item) => item.itemId).join('\n');
    }

    return report;
  };

  const renderAlerts = () => {
    const alerts = [];

    // Success Alert for found items
    if (eligibleItems.length > 0) {
      alerts.push(
        <Alert key="success" severity="success" sx={{ mb: 1 }}>
          {eligibleItems.length} item{eligibleItems.length !== 1 ? 's' : ''} successfully added to campaign
        </Alert>
      );
    }

    // Warning Alert for existing items
    if (alreadyTargetedItems.length > 0) {
      alerts.push(
        <Alert key="warning" severity="warning" sx={{ mb: 1 }}>
          {alreadyTargetedItems.length} item{alreadyTargetedItems.length !== 1 ? 's' : ''} already exist in this campaign
          <Typography variant="body2" color="warning.main" sx={{ mt: 0.5 }}>
            Items: {alreadyTargetedItems.map((item) => item.itemId).join(', ')}
          </Typography>
        </Alert>
      );
    }

    // Error Alert for truly invalid items (not found and not existing)
    if (invalidItems.length > 0) {
      alerts.push(
        <Alert key="error" severity="error" sx={{ mb: 1 }}>
          {invalidItems.length} item{invalidItems.length !== 1 ? 's' : ''} could not be found
          <Typography variant="body2" color="error" sx={{ mt: 0.5 }}>
            Not found: {invalidItems.join(', ')}
          </Typography>
        </Alert>
      );
    }

    return alerts;
  };

  if (!isOpen || (eligibleItems.length === 0 && invalidItems.length === 0 && alreadyTargetedItems.length === 0)) {
    return null;
  }

  return <Box>{renderAlerts()}</Box>;
};

export default AddItemsAlert;
