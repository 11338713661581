import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControlLabel,
  Modal,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { BidGroupDefaultSettings, BidOptimizationGroupDto } from '../../types/bid-optimization-group';
import { Campaign } from '../../types/campaign';

interface UpdateBidGroupDialogProps {
  isOpen: boolean;
  onClose: () => void;
  bidGroup: BidOptimizationGroupDto;
  onSave: (bidOptimizationGroupDto: BidOptimizationGroupDto) => Promise<void>;
}

const UpdateBidGroupDialog: React.FC<UpdateBidGroupDialogProps> = ({
  isOpen,
  onClose,
  bidGroup,
  onSave: handleUpdateBidGroup
}) => {
  const [bidGroupName, setBidGroupName] = useState<string>(bidGroup.name);
  const [selectedCampaignData] = useState<Partial<Campaign>[]>([]);
  const [acosTarget, setAcosTarget] = useState<number | null>(bidGroup.acosTarget);
  const [maxTargetBidAdjustmentPercentage, setMaxTargetBidAdjustmentPercentage] = useState<number>(
    bidGroup.maximumTargetBidAdjustmentPercentage ?? BidGroupDefaultSettings.maximumTargetBidAdjustmentPercentage
  );
  const [maxPlacementAdjustmentPercentage, setMaxPlacementBidAdjustmentPercentage] = useState<number>(
    bidGroup.maximumPlacementAdjustmentPercentage ?? BidGroupDefaultSettings.maximumPlacementAdjustmentPercentage
  );
  const [placementAcosThresholdPercentage, setPlacementAcosThresholdPercentage] = useState<number>(
    bidGroup.placementAcosThresholdPercentage ?? BidGroupDefaultSettings.placementAcosThresholdPercentage
  );
  const [shouldDecreaseBidForNoConversions, setShouldDecreaseBidForNoConversions] = useState<boolean>(
    bidGroup.shouldDecreaseBidForNoConversions ?? true
  );
  const [noConversionBidDecreasePercentage, setNoConversionBidDecreasePercentage] = useState<number>(
    bidGroup.noConversionBidDecreasePercentage ?? BidGroupDefaultSettings.noConversionBidDecreasePercentage
  );
  const [lookbackWindowDelayDays, setLookbackWindowDelayDays] = useState<number>(
    bidGroup.lookbackWindowDelayDays ?? BidGroupDefaultSettings.lookbackWindowDelayDays
  );
  const [isBackDropOpen, setBackDropOpen] = useState<boolean>(false);

  const isInvalidAcosTarget = useMemo(() => {
    return acosTarget !== null && (acosTarget < 0 || acosTarget > 100);
  }, [acosTarget]);

  const handleUpdateButtonClick = async () => {
    if (!acosTarget || isInvalidAcosTarget) {
      enqueueSnackbar('Please enter a valid ACoS target.', { variant: 'error' });
      return;
    }

    setBackDropOpen(true);

    await handleUpdateBidGroup({
      ...bidGroup,
      name: bidGroupName,
      acosTarget: acosTarget,
      maximumTargetBidAdjustmentPercentage: maxTargetBidAdjustmentPercentage,
      maximumPlacementAdjustmentPercentage: maxPlacementAdjustmentPercentage,
      placementAcosThresholdPercentage: placementAcosThresholdPercentage,
      shouldDecreaseBidForNoConversions: shouldDecreaseBidForNoConversions,
      noConversionBidDecreasePercentage: noConversionBidDecreasePercentage,
      lookbackWindowDelayDays: lookbackWindowDelayDays,
      campaignIds: selectedCampaignData
        .filter((campaign) => campaign.campaignId !== undefined)
        .map((campaign) => campaign.campaignId!)
    });

    setBackDropOpen(false);
    onClose();
  };

  const renderBidGroupUpdateForm = () => {
    return (
      <>
        <Stack mt={4} mb={4} spacing={2}>
          <TextField
            label="Bid Group Name"
            InputLabelProps={{ shrink: true }}
            name="bidGroupName"
            value={bidGroupName}
            placeholder="Bid Group 1"
            size="medium"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setBidGroupName(event.target.value)}
          />
          <TextField
            label="Target ACoS"
            InputLabelProps={{ shrink: true }}
            name="acosTarget"
            value={acosTarget}
            placeholder="30"
            size="medium"
            type="number"
            InputProps={{
              inputProps: { min: 1 },
              endAdornment: (
                <Typography color={'text.secondary'} variant="body1">
                  %
                </Typography>
              )
            }}
            error={isInvalidAcosTarget}
            helperText={isInvalidAcosTarget ? 'Enter a valid ACoS target' : null}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setAcosTarget(isNaN(parseInt(event.target.value)) ? null : parseInt(event.target.value))
            }
          />
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              label="Max Keyword Bid Adjustment"
              InputLabelProps={{ shrink: true }}
              name="maxTargetBidAdjustmentPercentage"
              value={maxTargetBidAdjustmentPercentage}
              placeholder="15"
              size="medium"
              sx={{ width: '100%' }}
              type="number"
              InputProps={{
                inputProps: { min: 0 }, // Constrain to positive numbers
                endAdornment: (
                  <Typography color={'text.secondary'} variant="body1">
                    %
                  </Typography>
                )
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                if (parseInt(value) >= 0 || value === '') {
                  setMaxTargetBidAdjustmentPercentage(parseInt(event.target.value));
                }
              }}
            />
            <Tooltip title="This setting limits how much a keyword or product target's bid can be adjusted during a single optimization run.">
              <HelpOutlineIcon sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              label="Max Placement Bid Adjustment"
              InputLabelProps={{ shrink: true }}
              name="maxPlacementAdjustmentPercentage"
              value={maxPlacementAdjustmentPercentage}
              placeholder="15"
              size="medium"
              type="number"
              sx={{ width: '100%' }}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <Typography color={'text.secondary'} variant="body1">
                    %
                  </Typography>
                )
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                if (parseInt(value) >= 0 || value === '') {
                  setMaxPlacementBidAdjustmentPercentage(parseInt(event.target.value));
                }
              }}
            />
            <Tooltip title="This setting prevents placement bids from being adjusted by more than the specified amount in any single optimization run.">
              <HelpOutlineIcon sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              label="Placement ACoS Threshold"
              InputLabelProps={{ shrink: true }}
              name="placementAcosThresholdPercentage"
              value={placementAcosThresholdPercentage}
              placeholder="25"
              size="medium"
              type="number"
              sx={{ width: '100%' }}
              InputProps={{
                inputProps: { min: 0 }, // Constrain to positive numbers
                endAdornment: (
                  <Typography color={'text.secondary'} variant="body1">
                    %
                  </Typography>
                )
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                if (parseInt(value) >= 0 || value === '') {
                  setPlacementAcosThresholdPercentage(parseInt(event.target.value));
                }
              }}
            />
            <Tooltip title="This setting defines a relative threshold for placement changes. For example, with a threshold of 50% and a target ACoS of 30%, the bounds would be 15% and 45%. If a placement's ACoS exceeds these bounds, the placement's multiplier will be updated.">
              <HelpOutlineIcon sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Tooltip title="Enable or disable bid reduction for targets with no conversions">
              <Switch
                checked={shouldDecreaseBidForNoConversions}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setShouldDecreaseBidForNoConversions(event.target.checked)
                }
                name="shouldDecreaseBidForNoConversions"
              />
            </Tooltip>
            <TextField
              label="No Conversions Bid Reduction"
              InputLabelProps={{ shrink: true }}
              name="noConversionBidDecreasePercentage"
              value={noConversionBidDecreasePercentage}
              placeholder="10"
              size="medium"
              disabled={!shouldDecreaseBidForNoConversions}
              sx={{ width: '100%' }}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <Typography color={'text.secondary'} variant="body1">
                    %
                  </Typography>
                )
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setNoConversionBidDecreasePercentage(Number(event.target.value))
              }
            />
            <Tooltip title="The percentage by which a target’s bid will be reduced if it has zero conversions">
              <HelpOutlineIcon sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              label="Lookback Window Delay"
              InputLabelProps={{ shrink: true }}
              name="lookbackWindowDelayDays"
              value={lookbackWindowDelayDays}
              placeholder="2"
              size="medium"
              type="integer"
              sx={{ width: '100%' }}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <Typography color={'text.secondary'} variant="body1">
                    Days
                  </Typography>
                )
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                if (value === '' || isNaN(parseInt(value))) {
                  setLookbackWindowDelayDays(0);
                } else {
                  setLookbackWindowDelayDays(parseInt(value));
                }
              }}
            />
            <Tooltip title="The number of recent days to ignore when calculating performance metrics for bid adjustments.">
              <HelpOutlineIcon sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </Stack>
        </Stack>
        <Box display="flex" justifyContent="flex-end" gap={1}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleUpdateButtonClick} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </>
    );
  };

  return (
    <Modal open={isOpen} onClose={onClose} closeAfterTransition>
      <Fade in={isOpen}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1 // Add this line to make the edges round
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Update Bid Group Settings
          </Typography>
          {renderBidGroupUpdateForm()}
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isBackDropOpen}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UpdateBidGroupDialog;
