import React, { useState } from 'react';
import { get } from 'lodash';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CurrencyInput } from '../../../../../shared/components/inputs/currency-input';
import AddedTargets from './added-targets';
import CategoryRecommendations from './category-recommendations';
import ProductTargetRecommendations from './product-recommendations';
import { allRefinementsMatch, buildAddedTargetListItemFromRecommendation } from './targeting-utils';
import { MatchType } from '../../../../types/targeting-refinements';
import { AMAZON_TARGET_MINIMUM_BID, ProductTargetMatchType } from '../../../../types/target';

interface ProductTargetingSettingsProps {
  profileId: string | null;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  productTargets: any;
  setProductTargets: (productTargets: any) => void;
  formikErrors: any;
  formikTouched: any;
  fieldName: string;
}

export const ProductTargetingSettings = (props: ProductTargetingSettingsProps) => {
  const { profileId, values, setFieldValue, formikErrors, formikTouched, fieldName } = props;
  const errorValue = get(formikErrors, fieldName);
  const touchedValue = get(formikTouched, fieldName);
  const hasError = Boolean(touchedValue && errorValue);
  const [bid, setBid] = useState(AMAZON_TARGET_MINIMUM_BID);
  const [tabIndex, setTabIndex] = useState(0);
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);
  const targetingClauses = values.items[currentItemIndex]?.targetingClauses || [];

  const [productRecommendations, setProductRecommendations] = useState<any[]>([]);
  const [productMatchType, setProductMatchType] = useState<MatchType>({ exact: true, expanded: false });
  const [categoryRecommendations, setCategoryRecommendations] = useState<any[]>([]);

  const handleAddTargets = (targets: any[]) => {
    if (!targets || targets.length < 1) return;

    const newItems = [...values.items];

    newItems[currentItemIndex] = {
      ...newItems[currentItemIndex],
      targetingClauses: [...newItems[currentItemIndex].targetingClauses, ...targets]
    };

    setFieldValue('items', newItems);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleRemoveTarget = (targetToRemove: any) => {
    const newItems = [...values.items];

    newItems[currentItemIndex] = {
      ...newItems[currentItemIndex],
      targetingClauses: newItems[currentItemIndex].targetingClauses.filter(
        (target: any) =>
          !(target.key === targetToRemove.key && allRefinementsMatch(target?.refinements, targetToRemove?.refinements))
      )
    };

    setFieldValue('items', newItems);
  };

  const handleRemoveAllTargets = () => {
    const newItems = [...values.items];

    newItems[currentItemIndex] = {
      ...newItems[currentItemIndex],
      targetingClauses: []
    };

    setFieldValue('items', newItems);
  };

  const handleAddAllRecommendations = () => {
    let recommendedTargets: any[] = [];

    if (tabIndex === 0) {
      recommendedTargets = categoryRecommendations.map((category) =>
        buildAddedTargetListItemFromRecommendation(category, 'category', bid)
      );
    } else {
      if (productMatchType.exact) {
        recommendedTargets = recommendedTargets.concat(
          productRecommendations.map((product) =>
            buildAddedTargetListItemFromRecommendation({ ...product, matchType: ProductTargetMatchType.Exact }, 'product', bid)
          )
        );
      }

      if (productMatchType.expanded) {
        recommendedTargets = recommendedTargets.concat(
          productRecommendations.map((product) =>
            buildAddedTargetListItemFromRecommendation({ ...product, matchType: ProductTargetMatchType.Expanded }, 'product', bid)
          )
        );
      }
    }

    const uniqueRecommendations = recommendedTargets.filter((recommendedTarget) =>
      targetingClauses.every(
        (targetingClause: any) =>
          !(
            targetingClause.key === recommendedTarget.key &&
            allRefinementsMatch(targetingClause?.refinements, recommendedTarget?.refinements)
          )
      )
    );

    if (uniqueRecommendations.length > 0) {
      handleAddTargets(uniqueRecommendations);
    }
  };

  const handleNext = () => {
    setCurrentItemIndex((prevIndex) => (prevIndex + 1 < values.items.length ? prevIndex + 1 : 0));
  };

  const handlePrev = () => {
    setCurrentItemIndex((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : values.items.length - 1));
  };

  return (
    <Card
      id={`field-${fieldName}`}
      sx={{
        border: hasError ? '1px solid red' : 'none',
        borderRadius: 2,
        p: 2,
        mb: 2
      }}
    >
      {hasError && (
        <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
          {Array.isArray(errorValue) ? errorValue.join(', ') : errorValue}
        </Alert>
      )}

      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Product Targeting
        </Typography>
        {values.items.length > 0 ? (
          <>
            <Box sx={{ my: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton onClick={handlePrev} color="primary">
                  <ChevronLeftIcon />
                </IconButton>
                <Card variant="outlined" sx={{ p: 1, flex: 1 }}>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          alt={`${values.items[currentItemIndex].title} item image`}
                          src={values.items[currentItemIndex].imageUrl}
                          variant="square"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Typography>ASIN: {values.items[currentItemIndex].asin}</Typography>}
                        secondary={<Typography>Title: {values.items[currentItemIndex].title}</Typography>}
                      />
                    </ListItem>
                  </List>
                </Card>
                <IconButton onClick={handleNext} color="primary">
                  <ChevronRightIcon />
                </IconButton>
              </Box>
            </Box>

            <Divider sx={{ my: 4 }} />
            <Grid container direction="row" spacing={2} alignItems="stretch">
              <Grid item xs={6} sx={{ height: 700 }}>
                <Box sx={{ height: '100%' }}>
                  <Box sx={{ mb: 2 }}>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                      <Tab label="Category Targeting" />
                      <Tab label="Individual Product Targeting" />
                    </Tabs>
                  </Box>
                  <Stack mb={2}>
                    <Box sx={{ my: 2 }} display="flex" justifyContent="space-between">
                      <CurrencyInput
                        size="small"
                        label="Bid"
                        name="bid"
                        error={bid < AMAZON_TARGET_MINIMUM_BID}
                        helperText={bid < AMAZON_TARGET_MINIMUM_BID ? `Bid must be at least $${AMAZON_TARGET_MINIMUM_BID}` : ' '}
                        sx={{ maxWidth: '180px' }}
                        value={bid}
                        onChange={(event) => setBid(Number(event.target.value))}
                      />
                    </Box>
                    {tabIndex === 0 && (
                      <CategoryRecommendations
                        profileId={profileId}
                        campaignId={null}
                        asins={values.items.map((item: any) => item.asin)}
                        handleAddTargets={handleAddTargets}
                        targets={targetingClauses}
                        categoryRecommendations={categoryRecommendations}
                        setCategoryRecommendations={setCategoryRecommendations}
                        bid={bid}
                        isSponsoredProductsCampaignBuilderRequest
                      />
                    )}
                    {tabIndex === 1 && (
                      <ProductTargetRecommendations
                        profileId={profileId}
                        campaignId={null}
                        asins={values.items.map((item: any) => item.asin)}
                        handleAddTargets={handleAddTargets}
                        targets={targetingClauses}
                        matchType={productMatchType}
                        setMatchType={setProductMatchType}
                        productRecommendations={productRecommendations}
                        setProductRecommendations={setProductRecommendations}
                        bid={bid}
                        isSponsoredProductsCampaignBuilderRequest
                      />
                    )}
                  </Stack>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" onClick={handleAddAllRecommendations}>
                      Add All Targets
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sx={{ height: 700 }}>
                <AddedTargets
                  targets={targetingClauses}
                  removeAllTargets={handleRemoveAllTargets}
                  removeTarget={handleRemoveTarget}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Typography>Add items in the step above to add product/category targets.</Typography>
        )}
      </CardContent>
    </Card>
  );
};
