import { Alert, Card, CardContent, Collapse, Grid, Typography } from '@mui/material';
import { get } from 'lodash';
import { CustomTypeOptionRadioButton } from '../../../../shared/components/custom-type-option-radio-button';
import { CampaignMode, TargetingMode } from '../../../types/campaign-builder-request';

interface TargetingSettingsProps {
  campaignMode: CampaignMode;
  setCampaignMode: (campaignMode: CampaignMode) => void;
  targetingMode: TargetingMode;
  setTargetingMode: (targetingMode: TargetingMode) => void;
  errors: any;
  touched: any;
  fieldName: string;
}

export const TargetingSettings = (props: TargetingSettingsProps) => {
  const { campaignMode, setCampaignMode, targetingMode, setTargetingMode, errors, touched, fieldName } = props;
  const campaignModeTouched = get(touched, `${fieldName}.campaignMode`);
  const campaignModeError = get(errors, `${fieldName}.campaignMode`);
  const targetingModeTouched = get(touched, `${fieldName}.targetingMode`);
  const targetingModeError = get(errors, `${fieldName}.targetingMode`);
  const showCampaignModeError = campaignModeTouched && campaignModeError;
  const showTargetingModeError = targetingModeTouched && targetingModeError;
  const hasError = Boolean(showCampaignModeError || showTargetingModeError);

  return (
    <Card
      id={`field-${fieldName}`}
      sx={{
        border: hasError ? '1px solid red' : 'none',
        borderRadius: 2,
        p: 2,
        mb: 2
      }}
    >
      <CardContent>
        {showCampaignModeError && (
          <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
            {campaignModeError}
          </Alert>
        )}
        {showTargetingModeError && (
          <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
            {targetingModeError}
          </Alert>
        )}

        <Typography gutterBottom variant="h5" component="div">
          Targeting Settings
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <CustomTypeOptionRadioButton
              typeOptionValue="auto"
              typeOptionLabel="Automatic"
              checked={campaignMode === CampaignMode.AUTO}
              handleClick={() => setCampaignMode(CampaignMode.AUTO)}
              sx={{ mb: 2, mt: 2, maxWidth: '500px' }}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTypeOptionRadioButton
              typeOptionValue="manual"
              typeOptionLabel="Manual"
              checked={campaignMode === CampaignMode.MANUAL}
              handleClick={() => setCampaignMode(CampaignMode.MANUAL)}
              sx={{ mb: 2, mt: 2, maxWidth: '500px' }}
            />
          </Grid>
        </Grid>

        <Collapse in={campaignMode === CampaignMode.MANUAL} sx={{ mt: 2 }}>
          <Typography gutterBottom variant="body1" component="div">
            Manual Targeting Type
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomTypeOptionRadioButton
                typeOptionValue="keyword"
                typeOptionLabel="Keyword"
                checked={targetingMode === TargetingMode.KEYWORD}
                handleClick={() => setTargetingMode(TargetingMode.KEYWORD)}
                sx={{ mb: 2, mt: 2, maxWidth: '500px' }}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTypeOptionRadioButton
                typeOptionValue="product"
                typeOptionLabel="Product Targeting"
                checked={targetingMode === TargetingMode.PRODUCT}
                handleClick={() => setTargetingMode(TargetingMode.PRODUCT)}
                sx={{ mb: 2, mt: 2 }}
              />
            </Grid>
          </Grid>
        </Collapse>
      </CardContent>
    </Card>
  );
};
