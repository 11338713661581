import React, { useEffect, useRef } from 'react';
import VectorLoading from './loading/vector-loading';

const SplashScreen = () => {
  const containerStyles: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    padding: '1rem',
    backgroundColor: 'neutral.900',
    zIndex: 2000
  };

  return (
    <div style={containerStyles}>
      <VectorLoading />
    </div>
  );
};

export default SplashScreen;
