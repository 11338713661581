import { Alert, Box, Button, Divider, MenuItem, Tooltip, Typography } from '@mui/material';
import {
  GridCallbackDetails,
  GridEventListener,
  GridRowSelectionModel,
  MuiEvent,
  useGridApiContext
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import EnableCampaignsActionButton from './enable-campaigns-action-button';
import DisableCampaignsActionButton from './pause-campaigns-action-button';
import PlacementBulkActionsButton from './placement-bulk-actions-button';
import BidOptimizerBulkActionButton from './bid-optimizer-bulk-action';
import { useSearchParams } from 'react-router-dom';
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { enqueueSnackbar } from 'notistack';
import useDialog from '../../../../shared/hooks/use-dialog';
import { CreateBudgetGroupDialog } from '../../budget/create-budget-group-dialog';
import { AddCampaignsToBudgetGroupDialog } from './add-campaigns-to-budget-group-dialog';
import { RemoveCampaignsFromBudgetGroupDialog } from './remove-from-budget-group-dialog';
import { CreateBudgetGroupWithCampaignsRequestDto } from '../../../types/budget-group';
import { BidOptimizationGroupCreateDto } from '../../../types/bid-optimization-group';
import CreateBidGroupDialog from '../../bid-groups/create-bid-group-dialog';
import RemoveCampaignsFromBidGroupDialog from '../../bid-groups/remove-campaigns-from-bid-group-dialog';
import { AddCampaignsToBidGroupDialog } from '../../bid-groups/add-campaigns-to-bid-group-dialog';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

interface CampaignBulkActionsProps {
  setIsLoading: (loading: boolean) => void;
  refreshCampaigns: () => void;
}

const CampaignBulkActions = ({ setIsLoading, refreshCampaigns }: CampaignBulkActionsProps) => {
  const apiRef = useGridApiContext();
  const [bulkActionsMenuAnchorElement, setActionsMenuAnchorElement] = useState<null | HTMLElement>(null);
  const { toggle: toggleCreateBudgetGroupDialog, isShowing: createBudgetGroupDialogIsShowing, dynamicKey } = useDialog();

  const {
    toggle: toggleAddCampaignsToBudgetGroupDialog,
    isShowing: addCampaignsToBudgetGroupDialogIsShowing,
    dynamicKey: addCampaignsToBudgetGroupKey
  } = useDialog();

  const {
    toggle: toggleRemoveCampaignsFromBudgetGroupDialog,
    isShowing: removeCampaignsFromBudgetGroupDialogIsShowing,
    dynamicKey: removeCampaignsFromBudgetGroupKey
  } = useDialog();

  const {
    toggle: toggleCreateBidGroupDialog,
    isShowing: createBidGroupDialogIsShowing,
    dynamicKey: createBidGroupDialogKey
  } = useDialog();

  const {
    toggle: toggleAddCampaignsToBidGroupDialog,
    isShowing: addCampaignsToBidGroupDialogIsShowing,
    dynamicKey: addCampaignsToBidGroupDialogKey
  } = useDialog();

  const {
    toggle: toggleRemoveCampaignsFromBidGroupDialog,
    isShowing: removeCampaignsFromBidGroupDialogIsShowing,
    dynamicKey: removeCampaignsFromBidGroupDialogKey
  } = useDialog();

  const actionsMenuIsOpen = Boolean(bulkActionsMenuAnchorElement);

  const handleBulkActionsButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setActionsMenuAnchorElement(event.currentTarget);
  };

  const handleActionsMenuClose = () => {
    setActionsMenuAnchorElement(null);
  };

  const [selectionModelHasRows, setSelectionModelHasRows] = useState(apiRef.current.getSelectedRows()?.size > 0);
  const [isSavingBulkAction, setIsSavingBulkAction] = useState(false);

  const [searchParams] = useSearchParams();
  const profileId = searchParams.get('profileId');
  const { createBudgetGroupWithCampaigns, createBidOptimizationGroup } = useAmazonApi();

  useEffect(() => {
    function initialize() {
      apiRef.current.subscribeEvent('rowSelectionChange', handleSelectionChange);
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectionChange: GridEventListener<'rowSelectionChange'> = (
    params: GridRowSelectionModel,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    setSelectionModelHasRows(params?.length > 0);
  };

  const handleCreateBudgetGroup = async (budgetGroupName: string) => {
    setIsSavingBulkAction(true);

    const selectedCampaignIds = Array.from(apiRef.current.getSelectedRows().values()).map((campaign) => campaign.campaignId);

    if (!profileId) {
      enqueueSnackbar('Profile ID is missing', { variant: 'error' });
      return;
    }

    const requestBody: CreateBudgetGroupWithCampaignsRequestDto = {
      budgetGroupName,
      campaignIds: selectedCampaignIds
    };

    const response = await createBudgetGroupWithCampaigns(profileId, requestBody);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    if (response.success) {
      if (location.pathname.includes('budgetGroups')) {
        refreshCampaigns();
      } else {
        apiRef.current.updateRows(
          selectedCampaignIds.map((campaignId) => ({
            campaignId: campaignId,
            campaignBudgetGroupId: response.body[0].id,
            campaignBudgetGroupName: budgetGroupName
          }))
        );
      }

      enqueueSnackbar('Budget group created', { variant: 'success' });
    }

    apiRef.current.setRowSelectionModel([]);
    setIsSavingBulkAction(false);
    handleActionsMenuClose();
  };

  const handleCreateBudgetGroupClick = () => {
    toggleCreateBudgetGroupDialog();
    handleActionsMenuClose();
  };

  const handleAddCampaignsToBudgetGroupClick = () => {
    toggleAddCampaignsToBudgetGroupDialog();
    handleActionsMenuClose();
  };

  const handleRemoveCampaignsFromBudgetGroupClick = () => {
    toggleRemoveCampaignsFromBudgetGroupDialog();
    handleActionsMenuClose();
  };

  const handleCreateBidGroupClick = () => {
    toggleCreateBidGroupDialog();
    handleActionsMenuClose();
  };

  const handleAddCampaignsToBidGroupClick = () => {
    toggleAddCampaignsToBidGroupDialog();
    handleActionsMenuClose();
  };

  const handleRemoveCampaignsFromBidGroupClick = () => {
    toggleRemoveCampaignsFromBidGroupDialog();
    handleActionsMenuClose();
  };

  const handleCreateBidGroup = async (bidOptimizationGroupCreateDto: BidOptimizationGroupCreateDto) => {
    if (!profileId) {
      return;
    }

    setIsSavingBulkAction(true);

    const selectedCampaignIds = Array.from(apiRef.current.getSelectedRows().values()).map((campaign) => campaign.campaignId);
    const response = await createBidOptimizationGroup({ ...bidOptimizationGroupCreateDto, campaignIds: selectedCampaignIds });

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return;
    }

    enqueueSnackbar('Bid Optimization Group created successfully', { variant: 'success' });
    toggleCreateBidGroupDialog();

    apiRef.current.updateRows(
      selectedCampaignIds.map((campaignId) => ({
        campaignId: campaignId,
        campaignBidOptimizationGroupId: response.body.id
      }))
    );

    apiRef.current.setRowSelectionModel([]);
    setIsSavingBulkAction(false);
  };

  return (
    <>
      <Tooltip title={selectionModelHasRows ? '' : 'select rows to enable bulk actions'} placement="top" arrow>
        <span>
          <Button
            id="demo-customized-button"
            aria-controls={actionsMenuIsOpen ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={actionsMenuIsOpen ? 'true' : undefined}
            disableElevation
            onClick={handleBulkActionsButtonClick}
            disabled={!selectionModelHasRows}
            sx={{ ml: 1 }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Bulk Actions
          </Button>
        </span>
      </Tooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={bulkActionsMenuAnchorElement}
        open={actionsMenuIsOpen}
        onClose={handleActionsMenuClose}
      >
        <Typography variant="subtitle2" fontWeight={'bold'} sx={{ p: 1 }}>
          Update
        </Typography>
        <DisableCampaignsActionButton
          setIsLoading={setIsLoading}
          refreshCampaigns={refreshCampaigns}
          handleClose={handleActionsMenuClose}
          selectionModelHasRows={selectionModelHasRows}
        />
        <EnableCampaignsActionButton
          setIsLoading={setIsLoading}
          refreshCampaigns={refreshCampaigns}
          handleClose={handleActionsMenuClose}
          selectionModelHasRows={selectionModelHasRows}
        />
        <PlacementBulkActionsButton
          setIsLoading={setIsLoading}
          refreshCampaigns={refreshCampaigns}
          handleMenuClose={handleActionsMenuClose}
          selectionModelHasRows={selectionModelHasRows}
        />
        <Divider orientation="horizontal" sx={{ mx: 0.5 }} />
        <Typography variant="subtitle2" fontWeight={'bold'} sx={{ p: 1 }}>
          Budget Groups
        </Typography>
        <Tooltip title={profileId ? '' : 'Filter by profile to enable this action'} placement="top" arrow>
          <MenuItem onClick={handleCreateBudgetGroupClick} disableRipple sx={{ fontSize: 14 }} disabled={!profileId}>
            Create Budget Group
          </MenuItem>
        </Tooltip>
        <Tooltip title={profileId ? '' : 'Filter by profile to enable this action'} placement="top" arrow>
          <MenuItem onClick={handleAddCampaignsToBudgetGroupClick} disableRipple sx={{ fontSize: 14 }} disabled={!profileId}>
            Add to Budget Group
          </MenuItem>
        </Tooltip>
        <MenuItem onClick={handleRemoveCampaignsFromBudgetGroupClick} disableRipple sx={{ fontSize: 14 }}>
          Remove From Budget Group
        </MenuItem>
        <Divider orientation="horizontal" sx={{ mx: 0.5 }} />
        <Typography variant="subtitle2" fontWeight={'bold'} sx={{ p: 1 }}>
          Bid Optimization Groups
        </Typography>
        <Tooltip title={profileId ? '' : 'Filter by profile to enable this action'} placement="top" arrow>
          <MenuItem onClick={handleCreateBidGroupClick} disableRipple sx={{ fontSize: 14 }} disabled={!profileId}>
            Create Bid Group
          </MenuItem>
        </Tooltip>
        <Tooltip title={profileId ? '' : 'Filter by profile to enable this action'} placement="top" arrow>
          <span>
            <MenuItem onClick={handleAddCampaignsToBidGroupClick} disableRipple sx={{ fontSize: 14 }} disabled={!profileId}>
              Add to Bid Group
            </MenuItem>
          </span>
        </Tooltip>
        <MenuItem onClick={handleRemoveCampaignsFromBidGroupClick} disableRipple sx={{ fontSize: 14 }}>
          Remove From Bid Group
        </MenuItem>
      </StyledMenu>
      {/* {profileId && (
        <BidOptimizerBulkActionButton
          setIsLoading={setIsLoading}
          refreshCampaigns={refreshCampaigns}
          selectionModelHasRows={selectionModelHasRows}
        />
      )} */}
      {createBudgetGroupDialogIsShowing && (
        <CreateBudgetGroupDialog
          isShowing={createBudgetGroupDialogIsShowing}
          toggle={toggleCreateBudgetGroupDialog}
          isLoading={isSavingBulkAction}
          dynamicKey={dynamicKey}
          handleCreateBudgetGroup={handleCreateBudgetGroup}
        />
      )}
      {addCampaignsToBudgetGroupDialogIsShowing && (
        <AddCampaignsToBudgetGroupDialog
          isShowing={addCampaignsToBudgetGroupDialogIsShowing}
          toggle={toggleAddCampaignsToBudgetGroupDialog}
          dynamicKey={addCampaignsToBudgetGroupKey}
          refreshCampaigns={refreshCampaigns}
        />
      )}
      {removeCampaignsFromBudgetGroupDialogIsShowing && (
        <RemoveCampaignsFromBudgetGroupDialog
          isShowing={removeCampaignsFromBudgetGroupDialogIsShowing}
          toggle={toggleRemoveCampaignsFromBudgetGroupDialog}
          dynamicKey={removeCampaignsFromBudgetGroupKey}
          refreshCampaigns={refreshCampaigns}
        />
      )}
      {createBidGroupDialogIsShowing && (
        <CreateBidGroupDialog
          isCampaignsGrid
          isShowing={createBidGroupDialogIsShowing}
          toggle={toggleCreateBidGroupDialog}
          dynamicKey={createBidGroupDialogKey}
          handleCreateBidGroup={handleCreateBidGroup}
          refreshEntities={refreshCampaigns}
        />
      )}
      {addCampaignsToBidGroupDialogIsShowing && (
        <AddCampaignsToBidGroupDialog
          isShowing={addCampaignsToBidGroupDialogIsShowing}
          toggle={toggleAddCampaignsToBidGroupDialog}
          dynamicKey={addCampaignsToBidGroupDialogKey}
          refreshCampaigns={refreshCampaigns}
        />
      )}
      {removeCampaignsFromBidGroupDialogIsShowing && (
        <RemoveCampaignsFromBidGroupDialog
          isShowing={removeCampaignsFromBidGroupDialogIsShowing}
          toggle={toggleRemoveCampaignsFromBidGroupDialog}
          dynamicKey={removeCampaignsFromBidGroupDialogKey}
          refreshCampaigns={refreshCampaigns}
        />
      )}
      <Divider orientation="vertical" flexItem />
    </>
  );
};

export default CampaignBulkActions;
