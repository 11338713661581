import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridColumnResizeParams,
  MuiEvent,
  useGridApiRef
} from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import BudgetGrid from '../../../shared/components/budget-grid';
import ProfileSelect from '../../../shared/components/page-components/profile-select';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import {
  CampaignBudget,
  MonthlyBudget,
  UpdateProfileMonthlyBudgetRequest,
  defaultMonthAmounts,
  getPreciseFloatingPointSum,
  listOfMonths
} from '../../../shared/types/budget';
import {
  createBudgetColumns,
  createCampaignBudgetColumns
} from '../../components/grid-components/column-configurations/budget-columns';
import { Month } from '../../../shared/utilities/date-utilities';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import dayjs from 'dayjs';

const DisabledButtonTooltipMessages = {
  noProfile: 'Please select a Profile before editing',
  overbudget: "Campaign budgets's total cannot exceed Client budget"
};

interface ClientBudgetRow extends MonthlyBudget {
  budgetItemName: string;
  id: number;
  total: number;
  year: number;
}

export function BudgetManager() {
  const clientGridApiRef = useGridApiRef();
  const campaignGridApiRef = useGridApiRef();

  const { getProfiles, getBudgetsViewData, updateCampaignAndProfileMonthlyBudgets, isValidMonthlyBudget } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isEditing, setIsEditing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [disabledEditButtonTooltip, setDisabledEditButtonTooltip] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [monthTotals, setMonthTotals] = useState<{ [key: string]: number }>({}); // { 'january': 0, 'february': 0, ... }
  const [calendarYear, setCalendarYear] = useState(new Date().getFullYear());
  const [clientBudgetColumns, setClientBudgetColumns] = useState<GridColDef[]>([]);
  const [campaignBudgetColumns, setCampaignBudgetColumns] = useState<GridColDef[]>([]);
  const [campaignRows, setCampaignRows] = useState<CampaignBudget[]>([]);

  const [searchParams] = useSearchParams();
  const profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;
  const highlightCampaigns = searchParams.get('highlightCampaigns')?.split(',').map(Number) || [];
  const [clientBudgetRows, setClientBudgetRows] = useState<ClientBudgetRow[]>([
    {
      budgetItemName: 'Budgets',
      id: 1,
      total: 0,
      ...defaultMonthAmounts
    } as ClientBudgetRow
  ]);

  const [clientBudgetGridSetting, setClientBudgetGridSetting] = useState<GridInitialStatePremium>(
    GridConfigSettings.WALMART_CLIENT_BUDGET.DefaultSetting as GridInitialStatePremium
  );
  const [campaignBudgetsGridSettings, setCampaignBudgetsGridSettings] = useState<GridInitialStatePremium>(
    GridConfigSettings.WALMART_CAMPAIGN_BUDGET.DefaultSetting as GridInitialStatePremium
  );

  const [clientBudgetColumnsWidths, setClientBudgetColumnsWidths] = useState<{ [key: string]: number }[]>([]);
  const [campaignBudgetColumnsWidths, setCampaignBudgetColumnsWidths] = useState<{ [key: string]: number }[]>([]);

  const isCurrentMonthWithinBudget: boolean = useMemo(() => {
    for (let row of campaignRows) {
      if (row.currentMonthSpend > row[dayjs().format('MMMM').toLowerCase() as Month]) {
        return false;
      }
    }

    return true;
  }, [campaignRows]);

  useEffect(() => {
    if (!campaignRows.length || campaignRows[0].year !== calendarYear) {
      return;
    }

    const totals = listOfMonths.reduce(
      (acc, month) => {
        acc[month] = campaignRows.reduce((total, row) => getPreciseFloatingPointSum([total, row[month]]), 0);
        return acc;
      },
      {} as { [key: string]: number }
    );

    totals.year = calendarYear;

    setMonthTotals(totals);
  }, [campaignRows, calendarYear]);

  useEffect(() => {
    if (!profileId) {
      setIsDisabled(true);
      setDisabledEditButtonTooltip(DisabledButtonTooltipMessages.noProfile);
      return;
    }

    const newColumns = createBudgetColumns(isValidMonthlyBudget);
    setClientBudgetColumns(newColumns);
  }, [profileId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newClientColumns = createBudgetColumns(isValidMonthlyBudget);
    const newCampaignColumns = createCampaignBudgetColumns(isValidMonthlyBudget);

    // hack to manually set column widths on every update, since MUI's aggregation interfere's with the column width settings
    const resizeColumns = (
      newColumns: GridColDef[],
      field: string,
      columnWidths: {
        [key: string]: number;
      }[]
    ) => {
      newColumns.forEach((column) => {
        let defaultWidth = 100;
        if (column.field === field) {
          defaultWidth = 150;
        }
        column.width = columnWidths.find((width) => width[column.field])?.[column.field] || defaultWidth;
        if (column.field === field) {
          column.resizable = true;
          return;
        }
        column.resizable = !isEditing;
      });
    };

    resizeColumns(newClientColumns, 'budgetItemName', clientBudgetColumnsWidths);
    resizeColumns(newCampaignColumns, 'campaignName', campaignBudgetColumnsWidths);

    setClientBudgetColumns(newClientColumns);
    setCampaignBudgetColumns(newCampaignColumns);
  }, [createCampaignBudgetColumns, campaignBudgetColumnsWidths, isEditing]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBudgetsViewData = async () => {
    if (!profileId) {
      return;
    }

    const response = await getBudgetsViewData(profileId, calendarYear);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error', persist: true });
      return;
    }

    if (response.body?.profileBudget) {
      setClientBudgetRows(response.body.profileBudget);
    }

    if (response.body?.campaignBudgets) {
      processCampaignMonthlyBudgetsFromFetchRequest(response.body?.campaignBudgets);
    }
  };

  const processCampaignMonthlyBudgetsFromFetchRequest = (campaignBudgets: any) => {
    setCampaignRows(campaignBudgets);
  };

  useEffect(() => {
    if (!profileId || !calendarYear) {
      return;
    }

    setIsLoading(true);

    fetchBudgetsViewData();

    setIsLoading(false);
  }, [profileId, calendarYear]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let shouldDisable = false;
    const monthlyBudgets: ClientBudgetRow =
      clientBudgetRows.find((row) => row.budgetItemName === 'Budgets') ?? ({} as ClientBudgetRow);

    if (!monthlyBudgets) {
      return;
    }

    listOfMonths.map((month: Month) => {
      if (Number(monthTotals[month]) > Number(monthlyBudgets[month])) {
        shouldDisable = true;
      }
    });

    setIsDisabled(shouldDisable);
    if (shouldDisable) {
      setDisabledEditButtonTooltip(DisabledButtonTooltipMessages.overbudget);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthTotals, clientBudgetRows]);

  const processClientBudgetRowUpdate = (newRow: any, oldRow: any) => {
    const rowIndex = clientBudgetRows.findIndex((row) => row.budgetItemName === newRow.budgetItemName);
    const newClientBudgetRows = [...clientBudgetRows];

    newClientBudgetRows[rowIndex] = newRow;
    setClientBudgetRows(newClientBudgetRows);

    return newRow;
  };

  const processCampaignBudgetRowUpdate = (newRow: any, oldRow: any) => {
    const campaignRowsCopy = [...campaignRows];
    const rowIndex = campaignRowsCopy.findIndex((row) => row.campaignId === newRow.campaignId);

    campaignRowsCopy[rowIndex] = newRow;
    setCampaignRows(campaignRowsCopy);

    return newRow;
  };

  const buildProfileBudgetDto = (): UpdateProfileMonthlyBudgetRequest => {
    const monthlyBudgets: ClientBudgetRow =
      clientBudgetRows.find((row) => row.budgetItemName === 'Budgets') ?? ({} as ClientBudgetRow);

    if (!monthlyBudgets) {
      return {
        profileId: profileId || 0,
        year: calendarYear
      } as UpdateProfileMonthlyBudgetRequest;
    }

    return {
      profileId: profileId || 0,
      year: calendarYear,
      january: monthlyBudgets['january'],
      february: monthlyBudgets['february'],
      march: monthlyBudgets['march'],
      april: monthlyBudgets['april'],
      may: monthlyBudgets['may'],
      june: monthlyBudgets['june'],
      july: monthlyBudgets['july'],
      august: monthlyBudgets['august'],
      september: monthlyBudgets['september'],
      october: monthlyBudgets['october'],
      november: monthlyBudgets['november'],
      december: monthlyBudgets['december']
    };
  };

  function getCellClassName(params: GridCellParams) {
    const monthlyBudgets: ClientBudgetRow =
      clientBudgetRows.find((row) => row.budgetItemName === 'Budgets') ?? ({} as ClientBudgetRow);

    if (!monthlyBudgets) {
      return '';
    }

    if (monthTotals[params.field] > monthlyBudgets[params.field as Month]) {
      return 'over-budget';
    }

    return '';
  }

  async function handleSaveClick() {
    updateColumnWidthState();
    resetGridsScroll();

    setIsSaving(true);

    const campaignGridRowIds = campaignGridApiRef.current?.getAllRowIds();

    // get edit mode of each cell in campaign grid
    const campaignGridCellModes = campaignGridRowIds.map((rowId) =>
      listOfMonths.map((month) => {
        return {
          [`${rowId}-${month}`]: campaignGridApiRef.current?.getCellMode(rowId, month)
        };
      })
    );

    // If any of the cells are in edit mode, exit out of save
    if (campaignGridCellModes.some((row) => row.some((cell) => Object.values(cell)[0] === 'edit'))) {
      enqueueSnackbar('Please finish editing all cells before saving', { variant: 'error' });
      setIsSaving(false);
      return;
    }

    const response = await updateCampaignAndProfileMonthlyBudgets(buildProfileBudgetDto(), campaignRows);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    } else {
      enqueueSnackbar('Budgets saved successfully', { variant: 'success' });
    }

    setIsEditing(false);
    setIsSaving(false);
  }

  const updateColumnWidthState = () => {
    const clientColumns = clientGridApiRef.current?.getAllColumns();
    const campaignColumns = campaignGridApiRef.current?.getAllColumns();

    const clientColumnsWidths = clientColumns?.map((column) => {
      return { [column.field]: column.width || 100 };
    });

    const campaignColumnsWidths = campaignColumns?.map((column) => {
      return { [column.field]: column.width || 100 };
    });

    setClientBudgetColumnsWidths(clientColumnsWidths);
    setCampaignBudgetColumnsWidths(campaignColumnsWidths);
  };

  const resetGridsScroll = () => {
    clientGridApiRef.current.scroll({ left: 0 });
    campaignGridApiRef.current.scroll({ left: 0 });
  };

  async function handleEditClick() {
    updateColumnWidthState();
    resetGridsScroll();

    setIsEditing(true);
  }

  function handleCancelClick() {
    updateColumnWidthState();
    resetGridsScroll();

    setIsLoading(true);
    fetchBudgetsViewData();
    setIsLoading(false);
    setIsEditing(false);
  }

  // Set the widths of the Name columns for both the client and campaign grids
  const handleColumnResize = useCallback(
    (params: GridColumnResizeParams, event: MuiEvent, details: GridCallbackDetails) => {
      const newWidth = params.width;
      const field = params.colDef.field;

      if (field === 'budgetItemName' || field === 'campaignName') {
        clientGridApiRef.current.setColumnWidth('budgetItemName', newWidth);
        campaignGridApiRef.current.setColumnWidth('campaignName', newWidth);
      } else if (field.indexOf('isActiveInVector') !== -1) {
        clientGridApiRef.current.setColumnWidth('isActiveInVectorMockColumn', newWidth);
        campaignGridApiRef.current.setColumnWidth('isActiveInVector', newWidth);
      } else {
        clientGridApiRef.current.setColumnWidth(field, newWidth);
        campaignGridApiRef.current.setColumnWidth(field, newWidth);
      }
    },
    [clientGridApiRef, campaignGridApiRef]
  );

  const handleClientGridScroll = useCallback(
    (scrollLeft: number) => {
      if (
        !campaignGridApiRef ||
        !campaignGridApiRef.current ||
        !(typeof campaignGridApiRef.current.scroll === 'function') ||
        !scrollLeft
      ) {
        return;
      }

      campaignGridApiRef.current?.scroll({ left: scrollLeft });
    },
    [campaignGridApiRef]
  );

  const handleCampaignGridScroll = useCallback(
    (scrollLeft: number) => {
      if (
        !clientGridApiRef ||
        !clientGridApiRef.current ||
        !(typeof clientGridApiRef.current.scroll === 'function' || !scrollLeft)
      ) {
        return;
      }

      clientGridApiRef.current?.scroll({ left: scrollLeft });
    },
    [clientGridApiRef]
  );

  const renderClientBudgetGrid = useCallback(() => {
    if (clientBudgetRows.length > 0 && clientBudgetColumns.length > 0 && !isLoading) {
      return (
        <BudgetGrid
          key="client-budget-grid"
          apiRef={clientGridApiRef}
          calendarYear={calendarYear}
          rows={!!profileId ? clientBudgetRows : []} // single row, pass as array
          columns={clientBudgetColumns}
          processRowUpdate={processClientBudgetRowUpdate}
          onColumnResize={handleColumnResize}
          initialState={clientBudgetGridSetting}
          getRowId={(row) => row.budgetItemName}
          canEdit={isEditing}
          loading={isLoading}
          onScroll={handleClientGridScroll}
        />
      );
    }

    return <Skeleton variant="rectangular" height={500} sx={{ borderRadius: '5px' }} />;
  }, [clientBudgetRows, isLoading, isEditing, clientBudgetColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderCampaignGrid = useCallback(() => {
    if (campaignRows.length > 0 && campaignBudgetColumns.length > 0 && !isLoading) {
      return (
        <BudgetGrid
          key="campaign-budget-grid"
          apiRef={campaignGridApiRef}
          calendarYear={calendarYear}
          rows={!!profileId ? [...campaignRows] : []}
          columns={campaignBudgetColumns}
          getCellClassName={getCellClassName}
          processRowUpdate={processCampaignBudgetRowUpdate}
          initialState={campaignBudgetsGridSettings}
          onColumnResize={handleColumnResize}
          getRowId={(row) => row.campaignId}
          canEdit={isEditing}
          loading={isLoading}
          isCampaignBudgetGrid
          onScroll={handleCampaignGridScroll}
          getRowClassName={(params) => (highlightCampaigns.includes(params.row.campaignId) ? 'highlighted-row' : '')}
        />
      );
    }

    return <Skeleton variant="rectangular" height={500} sx={{ borderRadius: '5px' }} />;
  }, [campaignRows, isLoading, isEditing, campaignBudgetColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      sx={{
        '& .highlighted-row': {
          backgroundColor: (theme) => `${theme.palette.error.light}20 !important`,
          '&:hover': {
            backgroundColor: (theme) => `${theme.palette.error.light}30 !important`
          }
        }
      }}
    >
      <Grid container direction={'row'} justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <Grid container sx={{}} direction="row" justifyContent={'flex-start'} alignItems="center">
            <Grid item>
              <FormControl>
                <ProfileSelect profileId={profileId} getProfiles={getProfiles} disabled={isEditing} />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Paper sx={{ py: 0.25 }}>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Button
                  startIcon={<ChevronLeftIcon />}
                  onClick={() => setCalendarYear(calendarYear - 1)}
                  disabled={isEditing}
                ></Button>
              </Grid>
              <Grid item>
                <Typography variant="h5">{calendarYear}</Typography>
              </Grid>
              <Grid item>
                <Button
                  endIcon={<ChevronRightIcon />}
                  onClick={() => setCalendarYear(calendarYear + 1)}
                  disabled={isEditing}
                ></Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title={isDisabled ? disabledEditButtonTooltip : ''} placement="top">
            <span style={{ float: 'right' }}>
              <Button
                variant={isEditing ? 'contained' : 'outlined'}
                onClick={isEditing ? handleSaveClick : handleEditClick}
                disabled={isDisabled}
                sx={{ display: !!profileId ? 'initial' : 'none' }}
              >
                {isEditing ? 'Save' : 'Edit'}
              </Button>
            </span>
          </Tooltip>
          {isEditing ? (
            <span style={{ float: 'right' }}>
              <Button onClick={handleCancelClick}>Cancel</Button>
            </span>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <Box sx={{ my: 3 }}>
        <Typography variant="h5" sx={{ mb: 1.5 }}>
          Client Budget
        </Typography>
        {renderClientBudgetGrid()}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1.5 }}>
        <Typography variant="h5">Campaign Budgets</Typography>
      </Box>
      {!!profileId && !isCurrentMonthWithinBudget && (
        <Alert sx={{ mb: 1 }} severity="error">
          <Typography variant="body2">
            Please assign a budget to the red-highlighted cells that is equal to or greater than their recorded spend for this
            month.
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            Hover over the red cells to view the spend unaccounted for.
          </Typography>
        </Alert>
      )}
      <Box
        sx={[
          { mb: 4 },
          ...listOfMonths.map((month: Month) => {
            const monthlyBudgets: ClientBudgetRow =
              clientBudgetRows.find((row) => row.budgetItemName === 'Budgets') ?? ({} as ClientBudgetRow);

            if (
              !monthlyBudgets ||
              monthlyBudgets.year !== calendarYear ||
              monthTotals.year !== calendarYear ||
              monthlyBudgets.year !== monthTotals.year
            ) {
              return {};
            }

            if (Number(monthTotals[month]) > Number(monthlyBudgets[month])) {
              return {
                [`& .header--validate-monthly-aggregate--${month}`]: {
                  backgroundColor: (theme: { palette: { error: { main: any } } }) => theme.palette.error.main
                }
              };
            }

            if (Number(monthTotals[month]) === Number(monthlyBudgets[month]) && isEditing) {
              return {
                [`& .header--validate-monthly-aggregate--${month}`]: {
                  backgroundColor: (theme: { palette: { success: { main: any } } }) => theme.palette.success.main
                }
              };
            }

            return {};
          })
        ]}
      >
        <Box height={'90vh'}>{renderCampaignGrid()}</Box>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSaving}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
