import { MenuItem, Box, Typography, IconButton, Tooltip, Divider } from '@mui/material';
import {
  ArchiveOutlined,
  UnarchiveOutlined,
  VisibilityOutlined,
  VisibilityOffOutlined,
  AccessTime,
  Archive
} from '@mui/icons-material';
import { formatDateForNotifications, NotificationItemProps } from './notifications-types-handlers';

export function NotificationItem(props: NotificationItemProps) {
  const { notification, selectedTab, handleNotificationItemClick, handleArchive, handleToggleRead } = props;

  return (
    <MenuItem
      key={notification.id}
      onClick={() => handleNotificationItemClick(notification)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        py: 1.5,
        px: 2,
        backgroundColor: notification.isRead ? 'inherit' : 'rgba(25, 118, 210, 0.08)',
        transition: 'background-color 0.2s',
        '&:hover': { backgroundColor: notification.isRead ? 'action.hover' : 'rgba(25, 118, 210, 0.12)' }
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body1" sx={{ flex: 1, fontWeight: notification.isRead ? 'normal' : 500 }}>
          {notification.notification.message}
        </Typography>
        <Box sx={{ display: 'flex', gap: 0.5 }}>
          {selectedTab === 0 ? (
            <Tooltip title="Archive notification">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleArchive(notification.id, true);
                }}
                sx={{
                  color: 'text.secondary',
                  '&:hover': { color: 'primary.main' }
                }}
              >
                <ArchiveOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Unarchive notification">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleArchive(notification.id, false);
                }}
                sx={{
                  color: 'text.secondary',
                  '&:hover': { color: 'primary.main' }
                }}
              >
                <UnarchiveOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={notification.isRead ? 'Mark as unread' : 'Mark as read'}>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleToggleRead(notification.id, notification.isRead);
              }}
              sx={{
                color: notification.isRead ? 'text.secondary' : 'primary.main',
                '&:hover': { color: 'primary.main' }
              }}
            >
              {notification.isRead ? <VisibilityOutlined fontSize="small" /> : <VisibilityOffOutlined fontSize="small" />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
        <AccessTime fontSize="small" sx={{ color: 'text.secondary', fontSize: '1rem' }} />
        <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {formatDateForNotifications(notification.notification.createdAt)}
          {notification.isArchived && notification.archivedDate && (
            <>
              <Divider orientation="vertical" flexItem />
              <Archive sx={{ fontSize: '0.875rem' }} />
              {`Archived ${formatDateForNotifications(notification.archivedDate)}`}
            </>
          )}
        </Typography>
      </Box>
    </MenuItem>
  );
}
