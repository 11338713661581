import { API_AUTH_SCOPE, API_ROOT } from '../../config';
import { Pageable } from '../types/pageable';
import { ResponseObject, executeGetRequest, executePatchRequest, executePostRequest } from '../utilities/fetch-utilities';
import { buildPageableParams } from '../utilities/url-utilities';
import { useAuth } from './use-auth';

const useSharedApi = () => {
  const { user, acquireToken } = useAuth();

  const getHealthCheckData = async () => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);
    return await executeGetRequest({
      endpoint: `${API_ROOT}/utilities/health`,
      authToken: authResponse.accessToken
    });
  };

  const getUserSetting = async (key: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return executeGetRequest({
      endpoint: `${API_ROOT}/api/UserSettings?email=${user.email}&key=${key}`,
      authToken: authResponse.accessToken
    });
  };

  const updateUserSetting = async (key: string, setting: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const postData = {
      email: user.email,
      key: key,
      value: setting
    };

    return await executePostRequest({
      endpoint: `${API_ROOT}/api/UserSettings`,
      body: postData,
      authToken: authResponse.accessToken
    });
  };

  const getAdvertizingClientsFromForeman = async (retailer: string) => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/ForemanPassthrough/GetAdvertisingClients?retailer=${retailer}`,
      authToken: authResponse.accessToken
    });
  };

  const getNotificationByUser = async (pageable: Pageable): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);
    const params = buildPageableParams({}, pageable);

    const endpoint = `${API_ROOT}/UserNotification/GetPages${params ? `?${params}&` : ''}`;

    return await executeGetRequest({
      endpoint: endpoint,
      authToken: authResponse.accessToken
    });
  };

  const updateIsReadNotification = async (userNotificationId: number, isRead: boolean) => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePatchRequest({
      endpoint: `${API_ROOT}/UserNotification/UpdateIsRead/${userNotificationId}?isRead=${isRead}`,
      authToken: authResponse.accessToken
    });
  };

  const updateIsArchivedNotification = async (userNotificationId: number, isArchived: boolean) => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePatchRequest({
      endpoint: `${API_ROOT}/UserNotification/UpdateIsArchived/${userNotificationId}?isArchived=${isArchived}`,
      authToken: authResponse.accessToken
    });
  };

  const markAllNotificationAsRead = async (isArchived: boolean) => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/UserNotification/MarkAllAsRead?isArchived=${isArchived}`,
      authToken: authResponse.accessToken
    });
  };

  const toggleNotificationArchive = async (isArchived: boolean) => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/UserNotification/ToggleUserNotificationsArchive?isArchived=${isArchived}`,
      authToken: authResponse.accessToken
    });
  };

  return {
    getHealthCheckData,
    getUserSetting,
    updateUserSetting,
    getAdvertizingClientsFromForeman,
    getNotificationByUser,
    updateIsReadNotification,
    updateIsArchivedNotification,
    markAllNotificationAsRead,
    archiveAllNotifications: toggleNotificationArchive
  };
};

export default useSharedApi;
