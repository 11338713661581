import { Box, Container, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { chunk, uniq } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import MetricsChart from '../../../shared/components/metrics-chart';
import Page from '../../../shared/components/page';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import TargetsBulkActions from '../../components/grid-components/bulk-actions/targets/targets-bulk-actions';
import { createColumns } from '../../components/grid-components/column-configurations/target-columns';
import { BreadCrumbParams, useAmazonApi } from '../../hooks/use-amazon-api-v2';
import { TargetUpdateDto } from '../../types/target';
import { AmazonExpressionTypes } from '../../types/targeting-refinements';

const Targets: FC = () => {
  const { getTargets, updateTarget, searchCatalogItems } = useAmazonApi();

  const { enqueueSnackbar } = useSnackbar();

  const [targets, setTargets] = useState<any[]>([]);
  const [productInfo, setProductInfo] = useState<any[] | null>(null);
  const [isLoadingProductInfo, setIsLoadingProductInfo] = useState<boolean>(true);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  let [searchParams] = useSearchParams();
  let profileId = useMemo(() => searchParams.get('profileId'), [searchParams]);
  let campaignId = useMemo(() => searchParams.get('campaignId'), [searchParams]);

  const breadcrumbParams: BreadCrumbParams[] = useMemo(() => [{ profileId }, { campaignId }], [profileId, campaignId]);

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange
  } = useVectorPerformanceGrid(GridConfigSettings.AMAZON_TARGETS);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchTargetsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchTargetsData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    setIsLoading(true);

    const response = await getTargets([...breadcrumbParams], pageable, beginDate, endDate, true);

    if (response.success) {
      setTargets(response.body.dataGrid.records);
      setRowCount(response.body?.dataGrid.totalFilteredRecords || DEFAULT_ROW_COUNT);
      setTimeSeriesData(response.body?.timeSeriesData);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    let updateTargetsRequest: TargetUpdateDto = { targetId: newRow.targetId };

    if (newRow.bid !== oldRow.bid) {
      updateTargetsRequest = { ...updateTargetsRequest, bid: newRow.bid };
    }

    if (newRow.state !== oldRow.state) {
      updateTargetsRequest = { ...updateTargetsRequest, state: newRow.state };
    }

    if (updateTargetsRequest.bid == null && updateTargetsRequest.state == null) {
      return oldRow;
    }

    const response = await updateTarget(updateTargetsRequest);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    const updatedSyncState = response.body[0].syncState;

    return { ...newRow, syncState: updatedSyncState };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProductInfo = async () => {
    setIsLoadingProductInfo(true);

    const asins = targets
      .map((target) => {
        return (
          target?.expression?.find((e: any) => e.type.toLowerCase() === AmazonExpressionTypes.asinSameAs.toLowerCase())?.value ??
          target?.expression?.find((e: any) => e.type.toLowerCase() === AmazonExpressionTypes.asinExpandedFrom.toLowerCase())
            ?.value
        );
      })
      .filter((asin: any) => asin);

    const uniqueAsins = uniq(asins);
    if (uniqueAsins.length < 1) {
      setIsLoadingProductInfo(false);
      return;
    }

    const chunkedAsins = chunk(uniqueAsins, 20);

    const requests = chunkedAsins.map((asins) => {
      return searchCatalogItems(asins);
    });

    const responses = await Promise.all(requests);

    const combinedProductInfo = responses.reduce((acc: any, response: any) => {
      return [...acc, ...response.body];
    }, []);

    setProductInfo(combinedProductInfo);
    setIsLoadingProductInfo(false);
  };

  useEffect(() => {
    if (targets?.length < 1) {
      return;
    }

    fetchProductInfo();
  }, [targets]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = React.useMemo(() => {
    return createColumns(productInfo, isLoadingProductInfo);
  }, [productInfo, isLoadingProductInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="Targets">
      <Container maxWidth="xl" style={{ height: '93vh', marginTop: '10px' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Typography variant="h5" mr={2}>
            Targets
          </Typography>
          {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
            <DataGridPremium
              rows={[]}
              columns={columns}
              loading={true}
              processRowUpdate={processRowUpdate}
              initialState={{}}
              key={uuidv4()}
              disableRowGrouping
            />
          ) : (
            <>
              {!!timeSeriesData && (
                <Box display="flex" alignItems={'center'} my={'1%'}>
                  <MetricsChart
                    timeSeriesData={timeSeriesData}
                    isMetricsChartLoading={isLoading}
                    chartSettingsKey="AmazonTargetsChartSetting"
                  />
                </Box>
              )}
              <VectorPerformanceGrid
                apiRef={apiRef}
                processRowUpdate={processRowUpdate}
                loading={isLoading}
                rows={targets}
                columns={columns}
                initialState={initialSettings?.config}
                saveGridConfig={saveGridConfig}
                dateConfig={{
                  dateSettings: dateSettings,
                  dateSettingsLoading: dateSettingsLoading,
                  saveDateConfig: (dateRange: DateRange<Dayjs>) =>
                    handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchTargetsData, setDateSettings)
                }}
                bottomMargin={30}
                getRowId={(row: any) => row.targetId}
                bulkActions={<TargetsBulkActions setIsLoading={setIsLoading} />}
                handleFilterModelChange={(newModel: GridFilterModel) => {
                  handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchTargetsData);
                }}
                handleSortModelChange={(newModel: GridSortModel) => {
                  handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchTargetsData);
                }}
                handlePageChange={(page: number) => {
                  handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchTargetsData);
                }}
                handlePageSizeChange={(pageSize: number) => {
                  handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchTargetsData);
                }}
                rowCount={rowCount}
              />
            </>
          )}
        </Stack>
      </Container>
    </Page>
  );
};

export default Targets;
