import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import type { SeverityPillColor } from '../../../../walmart/components/severity-pill';
import { ClosedCaptioningUpdateDto, Media, MediaStatus } from '../../../types/walmart-sams-club/media';
import { MediaErrorDetailModal } from './media-error-detail-modal';
import { MediaVideoPlayerModal } from './media-video-player';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useAdsApi from '../../../hooks/use-walmart-sams-club-api';
import { enqueueSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';

const statusMap: Record<MediaStatus, SeverityPillColor> = {
  LIVED: 'primary',
  AVAILABLE: 'success',
  PENDING: 'warning',
  FAILED: 'error'
};

interface MediaListTableProps {
  medias: Media[];
  search: any;
}

interface DownloadSubtitleButtonProps {
  fileUrl: string;
  fileType: 'vtt' | 'srt';
  fileName?: string;
}

interface UploadDialogProps {
  open: boolean;
  onClose: () => void;
  onUpload: (file: File) => void;
}

const UploadDialog: React.FC<UploadDialogProps> = ({ open, onClose, onUpload }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      onUpload(selectedFile);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload Closed Caption File</DialogTitle>
      <DialogContent>
        <input accept=".vtt,.srt" style={{ display: 'none' }} id="raised-button-file" type="file" onChange={handleFileChange} />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span">
            Choose File
          </Button>
        </label>
        {selectedFile && <Typography>{selectedFile.name}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleUpload} disabled={!selectedFile}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DownloadSubtitleButton: React.FC<DownloadSubtitleButtonProps> = ({ fileUrl, fileType, fileName }) => {
  const handleDownload = async () => {
    try {
      // Fetch the file content from the URL
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileExtension = fileType === 'vtt' ? 'vtt' : 'srt';

      // Create a link element and set the href to a Blob URL
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.${fileExtension}`;

      // Trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      enqueueSnackbar(`Failed to download file: ${error}`, { variant: 'error' });
    }
  };

  return (
    <Button variant="text" size="small" onClick={handleDownload} startIcon={<ClosedCaptionIcon />}>
      Download {fileType.toUpperCase()} File
    </Button>
  );
};

export const MediaListTable: FC<MediaListTableProps> = (props: MediaListTableProps) => {
  const { medias, search, ...other } = props;

  const { updateClosedCaptioning } = useAdsApi();

  const [error, setError] = useState<string | undefined>(undefined);
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [currentMediaId, setCurrentMediaId] = useState<number | null>(null);
  const [searchParams] = useSearchParams();

  const handleViewErrorDetails = (error: string) => {
    setError(error);
  };

  const handleCloseErrorDetails = () => {
    setError(undefined);
  };

  const handleViewVideoPlayer = (videoUrl: string) => {
    setVideoUrl(videoUrl);
  };

  const handleCloseVideoPlayer = () => {
    setVideoUrl(undefined);
  };

  const getFileType = (url: string) => {
    const extension = url.split('.').pop();
    return extension === 'vtt' ? 'vtt' : 'srt';
  };

  const handleUploadClick = (mediaId: number) => {
    setCurrentMediaId(mediaId);
    setUploadDialogOpen(true);
  };

  const handleUploadDialogClose = () => {
    setUploadDialogOpen(false);
    setCurrentMediaId(null);
  };

  const handleUpload = async (file: File) => {
    if (currentMediaId) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('mediaId', currentMediaId.toString());
      formData.append('profileId', searchParams.get('profileId')?.toString() || '0');

      try {
        const response = await updateClosedCaptioning(formData);

        if (response.success) {
          enqueueSnackbar('Closed caption file uploaded successfully', { variant: 'success' });
        } else {
          enqueueSnackbar(response?.errorMessage, {
            variant: 'error'
          });
        }
      } catch (error) {
        enqueueSnackbar('Failed to upload closed caption file. Please try again.', { variant: 'error' });
      }
    }
  };

  return (
    <div {...other}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Media</TableCell>
            <TableCell>Subtitles</TableCell>
            <TableCell align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {medias.map((media) => {
            const mediaName = media.name;
            const statusColor = statusMap[media.status] || 'warning';

            return (
              <TableRow key={media.mediaId}>
                <TableCell
                  onClick={() => handleViewVideoPlayer(media.videoUrl)}
                  sx={{
                    cursor: 'pointer'
                  }}
                >
                  <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    <img style={{ height: '90px', width: '160px' }} src={media.thumbnail} />
                    <Box sx={{ ml: 2 }}>
                      <Typography variant="body1">{mediaName}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        Media Id: {media.mediaId}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {media.captionUrl && (
                      <DownloadSubtitleButton
                        fileUrl={media.captionUrl}
                        fileType={getFileType(media.captionUrl)}
                        fileName={`${mediaName}_${media.mediaId}`}
                      />
                    )}
                    <Button
                      variant="text"
                      size="small"
                      onClick={() => handleUploadClick(media.mediaId)}
                      startIcon={<CloudUploadIcon />}
                    >
                      {media.captionUrl ? 'Update' : 'Upload'} Closed Caption
                    </Button>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  {media.status === 'FAILED' && !!media.details && (
                    <Button variant="text" sx={{ mr: 2 }} color="secondary" onClick={() => handleViewErrorDetails(media.details)}>
                      View Error Details
                    </Button>
                  )}
                  <Chip color={statusColor} label={media.status} variant={media.status === 'LIVED' ? 'outlined' : 'filled'} />
                </TableCell>
              </TableRow>
            );
          })}
          {medias.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography align="center" color="textSecondary" variant="body1">
                  {`No media available with status '${search}'`}
                </Typography>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
      {videoUrl ? <MediaVideoPlayerModal onClose={handleCloseVideoPlayer} open={!!videoUrl} videoUrl={videoUrl} /> : null}
      {!!error ? <MediaErrorDetailModal onClose={handleCloseErrorDetails} open={!!error} error={error} /> : null}
      {uploadDialogOpen ? (
        <UploadDialog open={uploadDialogOpen} onClose={handleUploadDialogClose} onUpload={handleUpload} />
      ) : null}
    </div>
  );
};

MediaListTable.propTypes = {
  medias: PropTypes.array.isRequired
};
