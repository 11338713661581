import { Alert, Card, CardContent, Typography } from '@mui/material';
import get from 'lodash/get';
import { AddItems } from './add-items';

interface ItemSettingsProps {
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  profileId: string | null;
  errors: any;
  touched: any;
  fieldName: string;
}

export const ItemSettings = (props: ItemSettingsProps) => {
  const { values, setFieldValue, profileId, errors, touched, fieldName } = props;
  const itemError = get(errors, fieldName);
  const itemTouched = get(touched, fieldName);
  const hasItemError = Boolean(itemTouched && itemError);

  return (
    <Card
      id={`field-${fieldName}`}
      sx={{
        border: hasItemError ? '1px solid red' : 'none',
        borderRadius: 2,
        p: 2,
        mb: 2
      }}
    >
      {hasItemError && (
        <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
          {Array.isArray(itemError) ? itemError.join(', ') : itemError}
        </Alert>
      )}

      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Select Items To Promote
        </Typography>
        <AddItems profileId={profileId} values={values} setFieldValue={setFieldValue} />
      </CardContent>
    </Card>
  );
};
