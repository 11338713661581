import { Alert, Card, CardContent, Typography } from '@mui/material';
import get from 'lodash/get';
import NegativeKeywords from './add-negative-keywords';

interface NegativeKeywordSettingsProps {
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: any;
  touched: any;
  fieldName: string;
}

export const NegativeKeywordSettings = (props: NegativeKeywordSettingsProps) => {
  const { values, setFieldValue, errors, touched, fieldName } = props;
  const negKeywordError = get(errors, fieldName);
  const negKeywordTouched = get(touched, fieldName);
  const hasError = Boolean(negKeywordTouched && negKeywordError);

  return (
    <Card
      id={`field-${fieldName}`}
      sx={{
        border: hasError ? '1px solid red' : 'none',
        borderRadius: 2,
        p: 2,
        mb: 2
      }}
    >
      <CardContent>
        {hasError && (
          <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
            {Array.isArray(negKeywordError) ? negKeywordError.join(', ') : negKeywordError}
          </Alert>
        )}

        <Typography gutterBottom variant="h5" component="div">
          Negative Keywords
        </Typography>

        <NegativeKeywords values={values} setFieldValue={setFieldValue} />
      </CardContent>
    </Card>
  );
};
