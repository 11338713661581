import { convertDateToYearMonthDay } from '../../shared/utilities/date-utilities';
import { BackendDynamicBidding } from './campaign';
import { CampaignBuilderItem } from './campaign-builder-item';

export enum State {
  ENABLED = 'ENABLED',
  PAUSED = 'PAUSED'
}

export enum MatchType {
  EXACT = 'EXACT',
  BROAD = 'BROAD',
  PHRASE = 'PHRASE'
}

export enum NegativeKeywordMatchType {
  Exact = 'Exact',
  Phrase = 'Phrase'
}

export enum SponsoredProductsCreateOrUpdateBudgetType {
  DAILY = 'DAILY'
}

export type AmazonCampaignBuilderKeywordDto = {
  matchType: MatchType;
  state: State;
  bid: number;
  keywordText: string;
};

export type AmazonCampaignBuilderItemDto = {
  asin: string;
  title?: string;
  imageUrl?: string;
  state: State;
  sku: string | null;
  keywords: AmazonCampaignBuilderKeywordDto[];
  itemNickname?: string;
};

export enum UserSelectedCampaignType {
  SINGLE_ITEM_SINGLE_TARGET = 'Single Item Single Target',
  SINGLE_ITEM_MULTIPLE_TARGETS = 'Single Item Multiple Targets',
  MULTIPLE_ITEMS_MULTIPLE_TARGETS = 'Multiple Items Multiple Targets'
}

export enum CampaignMode {
  MANUAL = 'manual',
  AUTO = 'auto'
}

export enum TargetingMode {
  KEYWORD = 'keyword',
  PRODUCT = 'product'
}

export enum CampaignStructureType {
  MULTI_ITEM_MULTI_KEYWORD_CAMPAIGN = 'MULTI_ITEM_MULTI_KEYWORD_CAMPAIGN',
  SINGLE_ITEM_SINGLE_KEYWORD_CAMPAIGN = 'SINGLE_ITEM_SINGLE_KEYWORD_CAMPAIGN',
  SINGLE_ITEM_MULTI_KEYWORD_CAMPAIGN = 'SINGLE_ITEM_MULTI_KEYWORD_CAMPAIGN',
  SINGLE_ITEM_SINGLE_PRODUCT_TARGET_CAMPAIGN = 'SINGLE_ITEM_SINGLE_PRODUCT_TARGET_CAMPAIGN',
  SINGLE_ITEM_MULTI_PRODUCT_TARGET_CAMPAIGN = 'SINGLE_ITEM_MULTI_PRODUCT_TARGET_CAMPAIGN',
  MULTI_ITEM_MULTI_PRODUCT_TARGET_CAMPAIGN = 'MULTI_ITEM_MULTI_PRODUCT_TARGET_CAMPAIGN',
  SINGLE_ITEM_AUTO_CAMPAIGN = 'SINGLE_ITEM_AUTO_CAMPAIGN',
  MULTI_ITEM_AUTO_CAMPAIGN = 'MULTI_ITEM_AUTO_CAMPAIGN'
}

export const singleItemCampaignStructureTypes = Object.values(CampaignStructureType).filter((type) => type.startsWith('SINGLE'));

export const campaignTypeMapping: Record<
  UserSelectedCampaignType,
  Record<CampaignMode, Record<TargetingMode, CampaignStructureType>>
> = {
  [UserSelectedCampaignType.SINGLE_ITEM_SINGLE_TARGET]: {
    [CampaignMode.MANUAL]: {
      [TargetingMode.KEYWORD]: CampaignStructureType.SINGLE_ITEM_SINGLE_KEYWORD_CAMPAIGN,
      [TargetingMode.PRODUCT]: CampaignStructureType.SINGLE_ITEM_SINGLE_PRODUCT_TARGET_CAMPAIGN
    },
    [CampaignMode.AUTO]: {
      [TargetingMode.KEYWORD]: CampaignStructureType.MULTI_ITEM_AUTO_CAMPAIGN,
      [TargetingMode.PRODUCT]: CampaignStructureType.MULTI_ITEM_AUTO_CAMPAIGN
    }
  },
  [UserSelectedCampaignType.SINGLE_ITEM_MULTIPLE_TARGETS]: {
    [CampaignMode.MANUAL]: {
      [TargetingMode.KEYWORD]: CampaignStructureType.SINGLE_ITEM_MULTI_KEYWORD_CAMPAIGN,
      [TargetingMode.PRODUCT]: CampaignStructureType.SINGLE_ITEM_MULTI_PRODUCT_TARGET_CAMPAIGN
    },
    [CampaignMode.AUTO]: {
      [TargetingMode.KEYWORD]: CampaignStructureType.SINGLE_ITEM_AUTO_CAMPAIGN,
      [TargetingMode.PRODUCT]: CampaignStructureType.SINGLE_ITEM_AUTO_CAMPAIGN
    }
  },
  [UserSelectedCampaignType.MULTIPLE_ITEMS_MULTIPLE_TARGETS]: {
    [CampaignMode.MANUAL]: {
      [TargetingMode.KEYWORD]: CampaignStructureType.MULTI_ITEM_MULTI_KEYWORD_CAMPAIGN,
      [TargetingMode.PRODUCT]: CampaignStructureType.MULTI_ITEM_MULTI_PRODUCT_TARGET_CAMPAIGN
    },
    [CampaignMode.AUTO]: {
      [TargetingMode.KEYWORD]: CampaignStructureType.MULTI_ITEM_AUTO_CAMPAIGN,
      [TargetingMode.PRODUCT]: CampaignStructureType.MULTI_ITEM_AUTO_CAMPAIGN
    }
  }
};

export interface CampaignBuilderRequest {
  name: string;
  startDate: string;
  endDate?: string | null;
  dailyBudget: number;
  campaignStrategy: string;
  amazonSponsoredProductsDynamicBidding: BackendDynamicBidding;
  budgetType: SponsoredProductsCreateOrUpdateBudgetType;
  state: State;
  isActiveInVector: boolean;
  items: AmazonCampaignBuilderItemDto[];
  campaignStructureType: CampaignStructureType;
  isBrandedCampaign: boolean;
  negativeKeywords: string[];
  universalKeywords: string[];
}
export interface UnifiedCampaignBuilderRequest extends Partial<CampaignBuilderRequest> {
  campaignId: string;
}

export const createCampaignBuilderRequest = (values: any): CampaignBuilderRequest => {
  return {
    name: values.name,
    startDate: convertDateToYearMonthDay(values.startDate),
    endDate: values.endDate ? convertDateToYearMonthDay(values.endDate) : null,
    dailyBudget: values.dailyBudget,
    budgetType: values.budgetType,
    campaignStrategy: values.campaignStrategy,
    amazonSponsoredProductsDynamicBidding: values.amazonSponsoredProductsDynamicBidding,
    state: State.ENABLED,
    isActiveInVector: true,
    items: values.items.map((item: CampaignBuilderItem) => {
      return {
        asin: item.asin,
        sku: item.sku,
        state: State.ENABLED,
        keywords: item.keywords.map((keyword: any) => {
          return {
            matchType: keyword.matchType,
            state: State.ENABLED,
            bid: keyword.bid,
            keywordText: keyword.keywordText
          };
        }),
        targetingClauses: item.targetingClauses.map((target: any) => {
          const targetingClause = target.targetingClause;
          return {
            state: State.ENABLED,
            bid: targetingClause.bid,
            expression: targetingClause.expression,
            resolvedExpression: targetingClause.resolvedExpression,
            type: 'manual'
          };
        }),
        itemNickname: item.itemNickname
      };
    }),
    campaignStructureType: values.campaignStructureType,
    isBrandedCampaign: values.isBrandedCampaign,
    negativeKeywords: values.negativeKeywords,
    universalKeywords: values.universalKeywords
  };
};
